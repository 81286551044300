import React, { useEffect } from 'react';
import { Device } from '@capacitor/device';

const SetDeviceBodyClass = () => {

    useEffect(() => {
		let doIt = async () => {
			const info = await Device.getInfo();
			const platform = info.platform;

			if ( !document.body.classList.contains(platform) ) {
				document.body.classList.add(platform);
			}
		}
		doIt();
	}, []);

    return(<></>)
};

export default SetDeviceBodyClass;