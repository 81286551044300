import React, { useState } from "react";
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { assets } from "helpers/generic";
import { getUser } from "helpers/user";
import BackButton from "components/BackButton";
import HamburguerMenu from "./HamburguerMenu";
import { __ } from "helpers/i18n";

const navbarHeight = 50;
const bottomMenuHeight = 80;

const LoggedLayoutStyled = styled.div`
	position: fixed;
	top: 0;
	min-width: 350px;
	max-width: 600px;
	height: 100%;
	margin: 0 auto;
	background: white;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`;

const Version = styled.div`
	position: absolute;
	top: 0;
	right: 10px;
	font-size: 9px;
`;

const Navbar = styled.div`
	height: ${props => props.height}px;
	width: 100%;

	.navbar {
		display: flex;
		flex-direction: column;
		width: 100%;

		.navbar-brand {
			margin: 0;
		}
	}

	#navbar-top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		#logo {
			width: 120px;
		}

		#btn-hamburguer {
			font-size: 20px;
			margin-right: 10px;
			border: 0;
			background: 0;
			width: 30px;


			img {
				width: 20px;
			}
		}
	}
`;

const BottomMenu = styled.div` 
	box-shadow: var(--plenoil-shadow);
	position: absolute;
	bottom: 0;
	width: 100%;
	height: ${props => props.height}px;
	display: flex;
	background: white;
	padding: 0 5px;

	a {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		text-decoration: none;
		color: var(--plenoil-black);
		font-weight: bold;
		font-size: 10px;
		line-height: 10px;
		text-align: center;

		img {
			height: 25px;
			margin-bottom: 10px;
		}
	}
`;

const Content = styled.div`
	position: relative;
	height: calc(100% - ${props => props.navbarHeight + props.bottomMenuHeight}px);
	overflow-y: auto;
	overflow-x: hidden;

	.page-title {
		font-weight: bold;
		text-align: center;
		padding: 8px;
		border-bottom: 2px solid var(--bs-gray-200);
		width: 200px;
		margin: 0 auto;
		margin-bottom: 15px;
		font-size: 18px;
		text-transform: uppercase;
	}
`;


export default function AppLayout(props) {
	let [hamburguerMenuVisible, setHamburguerMenuVisible] = useState(false);

	return (
		<LoggedLayoutStyled>
			<Version>v2.0.2</Version>

			{ hamburguerMenuVisible && 
				<HamburguerMenu 
					close={() => setHamburguerMenuVisible(false)}
				/> 
			} 
			<Navbar height={navbarHeight}>
				<div className="navbar navbar-expand-lg navbar-light">
					<div id="navbar-top">
						<BackButton />
						<div className="navbar-brand">
							<img id="logo" src={assets('/assets/img/' + (props.logo ? props.logo : 'Plenoil-logo-2022.svg'))} alt="logo" />
						</div>
						<button id="btn-hamburguer" onClick={() => setHamburguerMenuVisible(!hamburguerMenuVisible)}><img src={assets('/assets/img/menu-h.svg')} alt="menu" style={{visibility: getUser()?.id ? 'visible' : 'hidden'}} /></button>
					</div>
				</div>
			</Navbar>
			<Content navbarHeight={navbarHeight} bottomMenuHeight={getUser()?.id ? bottomMenuHeight : 0}>
				{props.children}
			</Content>
			{ getUser()?.id && 
				<BottomMenu height={bottomMenuHeight}>
					<NavLink to="/map">
						<img src={assets('/assets/img/ICONOS-MAPA.svg')} alt="icon" />
						{__('loggedlayout.bottomMenu.stations')}
					</NavLink>
					<NavLink to="/consumptions">
						<img src={assets('/assets/img/icono-consumos.svg')} alt="icon" />
						{__('loggedlayout.bottomMenu.consumptions')}
					</NavLink>
					<NavLink to="/refuelings">
					<img src={assets('/assets/img/icono-repostajes.svg')} alt="icon" />
						{__('loggedlayout.bottomMenu.refuelings')}
					</NavLink>
					<NavLink to="/invoices">
						<img src={assets('/assets/img/icono-facturas.svg')} alt="icon" />
						{__('loggedlayout.bottomMenu.invoices')}
					</NavLink>
					<NavLink to="/messages">
						<img src={assets('/assets/img/mensaje.svg')} alt="icon" />
						{__('loggedlayout.bottomMenu.messages')}
					</NavLink>
					<NavLink to="/account">
						<img src={assets('/assets/img/icono-datos.svg')} alt="icon" />
						{__('loggedlayout.bottomMenu.account')}
					</NavLink>
				</BottomMenu>
			}
		</LoggedLayoutStyled>
	);
}