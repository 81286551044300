import React, { useRef, useState } from "react";
import styled, { createGlobalStyle } from 'styled-components';
import { useNavigate, NavLink } from 'react-router-dom';
import { assets, jsonToArray } from "helpers/generic";
import { default as UserService } from 'services/User';
import { __, getLanguages, setLanguage } from "helpers/i18n";

const GlobalStyle = createGlobalStyle` 
	html, body {
		&.ios {
			#hamburguer-inner {
                padding-top: 50px;
            }
		}
	}
`;

const HamburguerMenuStyled = styled.div`
	position: fixed;
	top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    background: rgba(255,255,255,0.5);

    .inner {
        position: absolute;
        right: 0;
        max-width: 300px;
        width: 100%;
        height: calc(100% - 100px);
        padding: 25px;
        background: var(--plenoil-yellow2);

        #home {
            margin-top: 20px;
            filter: brightness(0) invert(1);
            width: 20px;
        }

        #links {
            margin-top: 40px;

            a {
                color: white;
                text-decoration: none;
                font-weight: 500;
                font-size: 18px;
                display: block;
                padding-bottom: 3px;
                margin-bottom: 3px;

                &:not(:last-of-type) {
                    border-bottom: 1px solid white;
                }
            }
        }

        #lang-selector {
            margin-top: 20px;

            select {
                padding: 2px 5px;
                background: white;

                option {
                    padding: 0;
                    text-align: left;
                }
            }
        }

        #btn-wash {
            margin: 35px 0;
            display: flex;

            img {
                width: 120px;
            }
        }

        #social {
            margin-top: 30px;

            a {
                display: inline-block;
                margin-right: 5px;

                img {
                    width: 35px;
                }
            }
        }

        #btn-logout {
            margin-top: 30px;
            color: white;
            text-decoration: none;
            font-weight: 500;
            font-size: 15px;
            display: inline-flex;
            align-items: center;

            img {
                margin-right: 5px;
                width: 12px;
            }
        }

        #bottom {
            position: absolute;
            bottom: 10px;
            width: 100%;

            a {
                color: var(--plenoil-black);
                text-decoration: none;
                font-weight: 700;
                font-size: 13px;
                display: inline-flex;
                align-items: center;

                img {
                    width: 12px;
                    margin-right: 5px;
                }
            }
        }
    }
`;

const DeleteAccountPopup = styled.div` 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;

    .popup-inner {
        position: relative;
        background: white;
        width: 300px;
        padding: 20px;
        box-shadow: var(--plenoil-shadow);
        border-radius: 10px;

        h1 {
            font-size: 20px;
            line-height: 20px;
            text-align: center;
        }

        #btn-close {
            position: absolute;
            top: -10px;
            right: -10px;
            background: none;
            border: none;
            border-radius: 50%;
            background: var(--plenoil-black);
            color: white;
        }
    }

    .privacy-link {
        display: block;
        color: var(--plenoil-black);
        font-size: 12px;
        text-align: center;
        margin-top: 15px;
        text-decoration: none;
    }
`;

export default function HamburguerMenu(props) {
    let menuRef = useRef(null);

    let navigate = useNavigate();

    let [showDeleteAccountPopup, setShowDeleteAccountPopup] = useState(false);
    let [deleteAccountPassword, setDeleteAccountPassword] = useState(null);
    let [deleteAccountErrors, setDeleteAccountErrors] = useState(null);

    let languages = getLanguages();
	languages = jsonToArray(languages);

	const setLang = (lang) => {
		if ( !lang ) return;
		setLanguage(lang);
	}

    const onClickHandler = (e) => {
        if ( e.target === menuRef.current ) {
            props.close();
        }
    }

    const deleteAccount = async () => {
        const c = window.confirm(__('hamburguerMenu.deleteAccountPopup.confirmMessage'));
        if ( !c ) return;

        setDeleteAccountErrors(null);

        let result = await UserService.deleteAccount(deleteAccountPassword);
        if ( !result.status ) {
            setDeleteAccountErrors(result.errors ?? null);
            return;
        }

        navigate('/logout');
    }

	return (
		<HamburguerMenuStyled 
            id="hamburguer-menu" 
            onClick={(e) => onClickHandler(e)}
            ref={menuRef}
        >
			<GlobalStyle />
            <div className="inner" id="hamburguer-inner">
                <NavLink to="/"><img id="home" src={assets('/assets/img/inicio.svg')} alt="home" onClick={() => props.close()} /></NavLink>
                
                <div id="links">
                    <NavLink to="/map" onClick={() => props.close()}>{__('hamburguerMenu.links.stations')}</NavLink>
                    <NavLink to="/consumptions" onClick={() => props.close()}>{__('hamburguerMenu.links.consumptions')}</NavLink>
                    <NavLink to="/refuelings" onClick={() => props.close()}>{__('hamburguerMenu.links.refuelings')}</NavLink>
                    <NavLink to="/invoices" onClick={() => props.close()}>{__('hamburguerMenu.links.invoices')}</NavLink>
                    <NavLink to="/messages" onClick={() => props.close()}>{__('hamburguerMenu.links.messages')}</NavLink>
                    <NavLink to="/account" onClick={() => props.close()}>{__('hamburguerMenu.links.account')}</NavLink>
                </div>

                <div id="lang-selector">
					<select onChange={(e) => setLang(e.target.value)} value="" className="btn btn-plenoil">
						<option value="">{__('splash.langselector.label')}</option>
						{languages.map(el => 
							<option key={el.key} value={el.key}>{el.value}</option>
						)}
					</select>
				</div>

                <NavLink to="/map?wash=true" id="btn-wash" onClick={() => props.close()}>
                    <img src={assets('/assets/img/plenoil-wash.png')} alt="wash" />
                </NavLink>

                <div id="social">
                    <a href="https://es.linkedin.com/company/plenoil" target="_blank" rel="noreferrer"><img src={assets('/assets/img/icono-rs-link-b.svg')} alt="linkedin" /></a>
                    <a href="https://www.instagram.com/plenoil/" target="_blank" rel="noreferrer"><img src={assets('/assets/img/icono-rs-instagram-b.svg')} alt="instagram" /></a>
                    <a href="https://www.facebook.com/plenoil/" target="_blank" rel="noreferrer"><img src={assets('/assets/img/icono-rs-facebook-b.svg')} alt="facebook" /></a>
                </div>

                <NavLink to="/logout" id="btn-logout">
                    <img src={assets('/assets/img/cerrar-sesion.svg')} alt="logout" />
                    {__('hamburguerMenu.logoutButton')}
                </NavLink>

                <div id="bottom">
                    <NavLink to="" onClick={() => setShowDeleteAccountPopup(true)}>
                        <img src={assets('/assets/img/eliminar-cuenta.svg')} alt="delete" />
                        {__('hamburguerMenu.deleteAccountButton')}
                    </NavLink>
                </div>

                { showDeleteAccountPopup &&
                    <DeleteAccountPopup id="delete-account-popup">
                        <div className="popup-inner">
                            <button id="btn-close" onClick={() => setShowDeleteAccountPopup(false)}><i className="bi bi-x"></i></button>
                            <h1>{__('hamburguerMenu.deleteAccountPopup.title')}</h1>
                            <input 
                                type="text" 
                                className="input-plenoil text-center" 
                                placeholder="******" 
                                value={deleteAccountPassword ?? ''} 
                                onChange={(e) => setDeleteAccountPassword(e.target.value)} 
                            />
                            { deleteAccountErrors && 
                                <div className="invalid-feedback d-block mt-2 text-center">
                                    {deleteAccountErrors}
                                </div>
                            }

                            <div className="text-center">
                                <button className="btn-plenoil btn-plenoil-blue2 mt-2" onClick={deleteAccount}>{__('hamburguerMenu.deleteAccountPopup.deleteButton')}</button>
                                <div></div>
						        <a href="https://plenoil.es/politica-de-privacidad-plenoil/" className="privacy-link" target="_blank" rel="noreferrer">{__('hamburguerMenu.deleteAccountPopup.privacyLink')}</a>
                            </div>
                        </div>
                    </DeleteAccountPopup>
                }
            </div>


		</HamburguerMenuStyled>
	);
}