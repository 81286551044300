import React, { useEffect, useState } from 'react';
import moment from 'moment/min/moment-with-locales';
import styled from 'styled-components';
import { jsonToArray, formatNumber } from 'helpers/generic';
import { default as RepostajeService } from 'services/Repostaje';
import { __ } from 'helpers/i18n';

const StatisticsStyled = styled.div` 
	width: 260px;
	padding: 15px 20px;
	padding-bottom: 0;
	margin: 0 auto;
	margin-top: 15px;
	margin-bottom: 20px;
	border-top: 2px solid var(--bs-gray-200);
	text-align: center;

	h3 {
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 10px;

		b {
			font-weight: 900 !important;
		}
	}
`;

export default function Statistics() {
	let [monthTotal, setMonthTotal] = useState(null);
	let [lastRepostajeTotal, setLastRepostajeTotal] = useState(null);

	useEffect(() => {
		const getData = async () => {
			// Search sales 2 month ago
			let sales = await RepostajeService.listSales(
				moment().subtract(2, 'month').startOf('month').format('YYYY-MM-DD'), 
				moment().format('YYYY-MM-DD')
			);
			sales = jsonToArray(sales).sort((a, b) => {
				// Resort because json key is integer and is showing unordered
				let aDate = moment(a.value.date, 'DD/MM/YY').unix();
				let bDate = moment(b.value.date, 'DD/MM/YY').unix();

				if ( aDate < bDate ) return -1;
				if ( aDate > bDate ) return 1;
				return 0;
			})
			setLastRepostajeTotal(sales[Object.keys(sales).length-1]?.value?.amount ?? 0);

			let monthSummary = await RepostajeService.monthSummary(moment().format('YYYY-MM-DD'));
			setMonthTotal(monthSummary?.totalEuros ?? 0);
		}
		getData();
	}, []);

	return (
        <StatisticsStyled>
            <div>
				<h3>{__('dashboard.statistics.lastRefueling')}: <b>{lastRepostajeTotal ? formatNumber(lastRepostajeTotal, 2, true, true) : '00,00'} €</b></h3>
			</div>
			<div>
				<h3>{__('dashboard.statistics.monthSummary')}: <b>{monthTotal ? formatNumber(monthTotal, 2, true, true) : '00,00'} €</b></h3>
			</div>
        </StatisticsStyled>
	);
}


