import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useStore from "./store";
import { assets } from 'helpers/generic';
import { __ } from 'helpers/i18n';

const BottomButtonsStyled = styled.div` 
	display: flex;
	flex-direction: column;
	align-items: end;
	position: absolute;
	bottom: 30px;
	right: 20px;
	z-index: 9999;
	width: 240px;

	select {
		border: 0;
		background: white;
		color: var(--plenoil-black);
		border-radius: 30px;
		padding: 0px 18px;
		font-size: 13px;
		box-shadow: var(--plenoil-shadow);
		width: 100%;
		height: 30px;

		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-appearance: none;
		-moz-appearance: none;

		background-image:
			linear-gradient(45deg, transparent 50%, gray 50%),
			linear-gradient(135deg, gray 50%, transparent 50%);
		background-position:
			calc(100% - 20px) calc(1em + 2px),
			calc(100% - 15px) calc(1em + 2px),
			calc(100% - .5em) .5em;
		background-size:
			5px 5px,
			5px 5px,
			1.5em 1.5em;
		background-repeat: no-repeat;
	}

	.form-switch {
        display: inline-flex;
        align-items: center;
		background: white;
		border-radius: 20px;
		padding-left: 40px;
		margin-bottom: 10px;
		height: 30px;
		width: 50%;
		box-shadow: var(--plenoil-shadow);

		.form-check-input {
			height: 18px;
			width: 40px;
			margin-bottom: 2px;
		}

        .form-check-input:checked {
            background-color: var(--plenoil-orange);
            border-color: var(--plenoil-orange);
        }

        label {
            margin-left: 10px;

            img {
                width: 70px;

				&#electriccharger {
					width: 20px;
					margin-right: 10px;
				}
            }
        }
    }

	button {
		border: 0;
		background: #efa134;
		color: white;
		height: 30px;
		padding: 0px 15px;
		border-radius: 30px;
		font-size: 10px;
		box-shadow: var(--plenoil-shadow);
		cursor: pointer !important;
		margin-top: 10px;
		width: 100%;
		font-weight: bold;

		display: flex;
		justify-content: left;
		align-items: center;

		img {
			opacity: 1;
			width: 20px;
			height: 15px;
			margin-right: 10px;
			filter: brightness(0) invert(1);
		}

		span {
			margin-top: 3px;
		}
	}
`;

const BottomButtons = (props) => {
	const navigate = useNavigate();

	const dispatch = useStore(state => state.dispatch);
	const withWash = useStore(state => state.withWash);
	const withElectriccharger = useStore(state => state.withElectriccharger);
	const provinces = useStore(state => state.provinces);

	const setWithElectriccharger = (status) => {
		dispatch({
			type: 'setWithElectriccharger',
			data: status
		});
	}

	const setWithWash = (status) => {
		dispatch({
			type: 'setWithWash',
			data: status
		});
	}

	const setProvince = (province_id) => {
		dispatch({
			type: 'setProvinceId',
			data: province_id
		});
	}

	const showList = () => {
		navigate('/stations-list');
	}

	return (
	    <BottomButtonsStyled id="top-filters">
			<div className="d-flex w-100">
				<div className="form-check form-switch d-flex justify-content-center me-1">
					<input className="form-check-input" type="checkbox" checked={withElectriccharger} onChange={() => setWithElectriccharger(!withElectriccharger)} id="electriccharger_check" />
					<label className="form-check-label" htmlFor="electriccharger_check">
						<img src={assets('/assets/img/recarga.svg')} id="electriccharger" alt="icon" />
					</label>
				</div>

				<div className="form-check form-switch">
					<input className="form-check-input" type="checkbox" checked={withWash} onChange={() => setWithWash(!withWash)} id="wash_check" />
					<label className="form-check-label" htmlFor="wash_check">
						<img src={assets('/assets/img/plenoil-wash.svg')} alt="icon" />
					</label>
				</div>
			</div>
			

			<select onChange={(e) => setProvince(e.target.value)}>
				<option value="">{__('map.topFilters.filterByProvince')}</option>
				{provinces?.map((el, idx) => {
					return (
						<option value={el.id} key={idx}>{el.name}</option>
					);
				})}
			</select>

			<button onClick={showList}><img src={assets('assets/img/listado-mapa.svg')} alt="list" /> <span>{__('map.viewlistbutton')}</span></button>
		</BottomButtonsStyled>
  	);
}

export default BottomButtons;