import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import CristalLoader from 'components/CristalLoader';
import { jsonToArray } from 'helpers/generic';
import { default as UsersService } from 'services/User';
import { toast } from 'react-toastify';
import { __ } from 'helpers/i18n';

const ContainerStyled = styled.div` 
	margin-top: 30px 0;

	input.input-plenoil,
	select.input-plenoil {
		padding: 10px 20px;
		font-size: 15px;
	}

	.btn-plenoil {
		padding: 10px 50px;
		font-size: 15px;
	}

	.label-plenoil {
		text-transform: none !important;
	}

	#invoices-period {
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		
		label {
			display: flex;
			align-items: center;
			font-size: 12px;
			font-weight: 500;
			margin-bottom: 5px;
			margin-right: 0;

			&:first-of-type {
				margin-right: 20px;
			}
		}

		input[type=radio] {
			width: 25px;
			height: 25px;
			margin-right: 10px;
		}
	}

	#invoicing-help {
		color: var(--bs-gray-700);
		width: 90%;
		margin: 0 auto;
		margin-top: 10px;
		margin-bottom: 20px;
		font-size: 12px;
		font-weight: 500;
	}

	h2#vehicles-title {
		font-size: 13px;
		color: var(--bs-gray-600);
		text-transform: uppercase;
	}

	#vehicles-wrapper {
		margin-top: 10px;
		
		.vehicle-row {
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			button.remove {
				background: var(--plenoil-orange2);
				border-radius: 50%;
				border: 0;
				color: var(--plenoil-white);
				margin-right: 10px;
				width: 16px;
				height: 16px;
				font-size: 11px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		#add-vehicle {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 10px 20px;
		}
	}
`;

const ConcentradorProcessCristal = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.8);
	border-radius: 30px;
	padding: 20px;
	z-index: 5;

	span {
		background: white;
		color: var(--plenoil-blue2);
		border-radius: 30px;
		text-align: center;
		display: block;
		padding: 10px 20px;
		box-shadow: var(--plenoil-shadow);
	}
	&:after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		font-size: 20px;
		text-align: center;
		background: white;
	}
`;

const FieldsWrapper = styled.div` 
	padding: 20px;
	position: relative;
`;

const Buttons = styled.div` 
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 30px;

	button {
		width: 100%;
	}

	a {
		width: 100%;
		text-align: center;
		color: var(--plenoil-black);
		text-decoration: none;
		font-size: 12px !important;
		font-weight: bold;
	}
`;

let checkConcentradorProcessInterval = null;

export default function UserEdit() {
	let [forceReload, setForceReload] = useState(null);
	let [loading, setLoading] = useState(false);
	let [data, setData] = useState({
		matriculas: {}
	});

	useEffect(() => {
		const getData = async () => {
			setLoading(true);

			let data = await UsersService.getData();
			setData(data);

			setLoading(false);
		}
		getData();
	}, [forceReload]);

	useEffect(() => {
		if ( data?.concentrador_process ) {
			clearInterval(checkConcentradorProcessInterval);
			checkConcentradorProcessInterval = setInterval(() => {
				setForceReload(Math.random());
			}, 5000);
		}

		return function cleanup() {
			clearInterval(checkConcentradorProcessInterval);
		}
	}, [data]);

	const setInvoicesPeriod = (value) => {
		setData((prev) => ({...prev, invoices_period: value}));
	}

	const addVehicle = () => {
		let newVehicles = {...data.matriculas};
		newVehicles[''] = '';
		setData((prev) => ({...prev, matriculas: newVehicles}));
	}

	const updateVehicle = (idx, value) => {
		let newVehicles = {...data.matriculas};
		newVehicles[idx] = value;
		setData((prev) => ({...prev, matriculas: newVehicles}));
	}

	const removeVehicle = (idx) => {
		let newVehicles = {...data.matriculas};
		delete newVehicles[idx];
		setData((prev) => ({...prev, matriculas: newVehicles}));
	}

	const saveInvoicingData = async () => {
		if ( !data ) return;

		setLoading(true);
		let result = await UsersService.updateInvoicingData({
			invoices_period: data.invoices_period === 2 ? 'individual' : 'mensual',
			vehicles: data.matriculas
		});
		if (result?.status) {
			toast.success(__('account.invoicingEdit.successMessage'));
			setForceReload(Math.random()); // Force reload data to show concentrador cristal
		} else {
			toast.error(__('account.invoicingEdit.errorMessage'));
		}
		setLoading(false);
	}

	return (
		<LoggedLayout>
			{loading && <CristalLoader />}
			<ContainerStyled>
				<div className="page-title">{__('account.invoicingEdit.title')}</div>

				<FieldsWrapper>
				{ data?.concentrador_process && <ConcentradorProcessCristal><span>{__('account.invoicingEdit.concentrador.onprogressmessage')}</span></ConcentradorProcessCristal> }

				<div id="invoices-period">
					<label className="plenoil-label">
						<input type="radio" checked={data?.invoices_period === 2} onChange={() => setInvoicesPeriod(2)} />
						{__('account.invoicingEdit.porsuministro.label')}
					</label>
					<label className="plenoil-label">
						<input type="radio" checked={data?.invoices_period === 0} onChange={() => setInvoicesPeriod(0)} />
						{__('account.invoicingEdit.mensuales.label')}
					</label>
				</div>

				<div id="invoicing-help">
					<div className="mb-2">
						<b>{__('account.invoicingEdit.porsuministro.label')}:</b> {__('account.invoicingEdit.porsuministro.text')}
					</div>
					<div>
						<b>{__('account.invoicingEdit.mensuales.label')}:</b> {__('account.invoicingEdit.mensuales.text')}
					</div>
				</div>	

				<div className="mt-4">
					<h2 id="vehicles-title">{__('account.invoicingEdit.vehicles.title')}</h2>
					<div id="vehicles-wrapper">
						{data?.matriculas && jsonToArray(data.matriculas).map((el, idx) => {
							return (
								<div key={idx} className="vehicle-row">
									<button onClick={() => removeVehicle(el.key)} className="remove"><i className="bi bi-x"></i></button>
									<input 
										type="text" 
										className="input-plenoil" 
										value={el.value} 
										onChange={(e) => updateVehicle(el.key, e.target.value)} 
										placeholder={__('account.invoicingEdit.vehicles.placeholder')} 
										readOnly={el.key.length > 0 ? true : false}
									/>
								</div>
							);
						})}
						
						{ (!data?.matriculas || Object.keys(data.matriculas)[Object.keys(data.matriculas).length - 1] !== '') &&
							<div className="vehicle-row">
								<button className="remove invisible"></button>
									<button className="btn-plenoil btn-plenoil-blue2 outline" id="add-vehicle" onClick={addVehicle}>
									{__('account.invoicingEdit.vehicles.addButton')} <i className="bi bi-plus"></i>
									</button>
							</div>
						}		
					</div>
				</div>
					
					<Buttons>
						<button className="btn-plenoil btn-plenoil-blue2" onClick={saveInvoicingData}>{__('account.invoicingEdit.saveButton')}</button>
						<NavLink to="/account" className="btn-plenoil mt-2">{__('account.invoicingEdit.cancelButton')}</NavLink>
					</Buttons>
				</FieldsWrapper>
			</ContainerStyled>
		</LoggedLayout>
	);
}


