import React, { useEffect } from 'react';
import styled from 'styled-components';
import { assets } from 'helpers/generic';
import { setMaintenanceMode } from 'helpers/config';

const Styled = styled.div` 
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999999;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	user-select: none;
	text-align: center;
	background: white;
	padding: 20px;
	font-size: 30px;
	line-height: 30px;

	img {
		width: 100px;
		margin-top: 20px;
	}

	button {
		position: absolute;
		top: 10px;
		right: 10px;
		border: 0;
		background:none;
		background: var(--plenoil-blue2);
		color: white;
		border-radius: 50%;
		font-size: 18px;
		line-height: 18px;
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export default function MaintenanceMode() {
	
	useEffect(() => {
		document.activeElement.blur();
	}, [])

	return (
		<Styled>
			<button onClick={() => setMaintenanceMode(false)}><i className="bi bi-x"></i></button>
			Estamos realizando tareas de mantenimiento, disculpe las molestias.
			<img src={assets('/assets/img/Plenoil-simple.svg')} id="loader" alt="loader" />
		</Styled>
	);
}


