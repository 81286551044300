import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import NotLoggedLayout from 'layouts/NotLoggedLayout';
import { assets } from 'helpers/generic';
import { getUser } from 'helpers/user';
import { default as RegisterService } from 'services/Register';
import { toast } from "react-toastify";
import CristalLoader from "components/CristalLoader";
import { __ } from "helpers/i18n";

const RegisterStyled = styled.div` 
	position: relative;
	padding: 15px;

	#user {
		display: block;
		width: 100px;
		margin: 0 auto;
	}

	#register-form {
		background: white;
		margin: 0 auto;

		h1 {
			color: var(--plenoil-orange);
			font-size: 20px;
			margin-bottom: 0;
			font-weight: 500;
		}

		h2 {
			display: block;
			font-size: 15px;
			margin-bottom: 10px;
			font-weight: 700;
			color: var(--plenoil-blue);
			text-transform: uppercase;
			margin-left: 20px;
		}

		p {
			font-size: 14px;
			font-weight: 500;
		}

		#invoicing-options {
			display: flex;
			align-items: center;

			b {
				font-weight: 500;
				font-size: 14px;
			}

			.label-plenoil {
				display: inline-flex;
				align-items: center;
			}

			input[type=radio] {
				width: 30px;
				height: 30px;
			}
		}

		#invoicing-help {
			margin-top: 25px;
			margin-left: 20px;
			font-size: 12px;
			font-weight: 500;

			b {
				font-weight: 700;
			}
		}

		#vehicles {

			.vehicle-row {
				display: flex;
				align-items: center;
				margin-bottom: 15px;

				button {
					border: 0;
					background: var(--plenoil-red);
					border-radius: 50%;
					width: 15px;
					height: 15px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: white;
					margin-right: 5px;
				}
			}
		}

		#bottom {
			color: var(--plenoil-blue);
			margin-top: 50px;
			text-align: center;

			label {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 13px;
				
				input {
					margin-right: 8px;
				}

				a {
					color: var(--plenoil-blue2);
					font-weight: 500;
					user-select: none;
				}
			}

			#cancel {
				font-weight: 700;
				color: var(--plenoil-black);
				text-decoration: none;
				font-size: 14px;
				text-transform: uppercase;
			}
		}
	}
`;

export default function Register() {
	let [loading, setLoading] = useState(false);
	let [provinces, setProvinces] = useState([]);
	let [data, setData] = useState({
		invoices_period: 'individual',
		vehicles: ['']
	});
	let [vatnumberExists, setVatnumberExists] = useState(false);
	let [policyAccepted, setPolicyAccepted] = useState(false);
	let [errors, setErrors] = useState({});
	let [registrationCompleted, setRegistrationCompleted] = useState(false);

	useEffect(() => {
		const loadProvinces = async () => {
			let provinces = await RegisterService.getProvinces();
			setProvinces(provinces);
		}
		loadProvinces();
	}, []);

	const setDataField = (name, value) => {
		setData((prev) => ({
			...prev,
			[name]: value
		}));
	}

	const setVehicle = (idx, value) => {
		let newVehicles = [...data.vehicles];
		newVehicles[idx] = value;
		setDataField('vehicles', newVehicles);
	}

	const addVehicle = (idx) => {
		let newVehicles = [...data.vehicles];
		newVehicles.push('');
		setDataField('vehicles', newVehicles);
	}

	const removeVehicle = (idx) => {
		let newVehicles = [...data.vehicles];
		newVehicles.splice(idx, 1);
		setDataField('vehicles', newVehicles);
	}

	const postalCodeCheck = (cp) => {
		let province = provinces?.filter(el => el.cp_control === cp)[0];
		if (province) setDataField('province_id', province.id);
	}

	const checkExistingVatnumber = async (vatnumber) => {
		if ( !vatnumber.trim() ) return false;

		setLoading(true);
		let result = await RegisterService.checkExistingVatnumber(vatnumber);
		setLoading(false);
		setVatnumberExists(result ? true : false);
	}

	const register = async (e) => {
		setErrors({});
		setLoading(true);

		//--------------------------------------------------------------------------------------//
		//                                        Step 1                                        //
		//--------------------------------------------------------------------------------------//
		let step1Result = await RegisterService.registerStep1({
			vatnumber: data.vatnumber,
            email: data.email,
            email_confirmation: data.email_confirmation,
            password: data.password
		});
		if ( step1Result && !step1Result.status ) {
			setErrors(step1Result.errors ?? {});
			toast.error(__('register.registerErrorMessage'));
			setLoading(false);
			return;
		}
		if ( step1Result && step1Result.string ) { // If user exists in octan show registration completed
			setRegistrationCompleted(step1Result.string);
			setLoading(false);
			return;
		}

		
		//--------------------------------------------------------------------------------------//
		//                                        Step 2                                        //
		//--------------------------------------------------------------------------------------//
		let step2Result = await RegisterService.registerStep2({
			name: data.name,
			invoices_period: data.invoices_period
		});
		if ( step2Result && !step2Result.status ) {
			setErrors((prev) => ({...prev, ...step2Result.errors}));
			toast.error(__('register.registerErrorMessage'));
			setLoading(false);
			return;
		}
			

		//--------------------------------------------------------------------------------------//
		//                                        Step 3                                        //
		//--------------------------------------------------------------------------------------//
		let step3Result = await RegisterService.registerStep3({
			postalcode: data.postalcode,
            address: data.address,
            city: data.city,
            province_id: data.province_id,
            phone: data.phone,
            vehicles: data.vehicles.map(el => ({matricula: el}))
		});
		if ( step3Result && !step3Result.status ) {
			setErrors((prev) => ({...prev, ...step3Result.errors}));
			toast.error(__('register.registerErrorMessage'));
			setLoading(false);
			return;
		}


		//--------------------------------------------------------------------------------------//
		//                                      Step Final                                      //
		//--------------------------------------------------------------------------------------//
		let stepFinalResult = await RegisterService.registerStepFinal({
			vatnumber: data.vatnumber,
            email: data.email,
            email_confirmation: data.email_confirmation,
            password: data.password,

			name: data.name,
			invoices_period: data.invoices_period,

			postalcode: data.postalcode,
            address: data.address,
            city: data.city,
            province_id: data.province_id,
            phone: data.phone,
            vehicles: data.vehicles.map(el => ({matricula: el}))
		});
		if ( stepFinalResult && !stepFinalResult.status ) {
			setErrors((prev) => ({...prev, ...stepFinalResult.errors}));
			toast.error(__('register.registerErrorMessage'));
			setLoading(false);
			return;
		}

		setRegistrationCompleted(stepFinalResult.string);
		setLoading(false);
	};

	const resendConfirmationEmail = async () => {
		setLoading(true);
		let result = await RegisterService.resendConfirmationEmail(registrationCompleted);
		if ( result ) {
			toast.success(__('register.confirmationMessageResent'))
		} else {
			toast.error(__('register.genericErrorMessage'));
		}
		setLoading(false);
	}

	if ( getUser() ) return <Navigate to="/" />;

	// Render
	return (
		<NotLoggedLayout>
			{loading && <CristalLoader />}
			<RegisterStyled>
				<div id="register-form" >
					{ !registrationCompleted &&
						<React.Fragment>
							<img id="user" src={assets('/assets/img/cliente-inicio-redondo.svg')} alt="user" />

							<div className="mt-4">
								<h2>{__('register.title.userData')}</h2>
								
								<div className="row">
									{ !vatnumberExists &&
										<div className="mb-2">
											<label className="label-plenoil">{__('register.inputs.name.label')}</label>
											<input type="text" className="input-plenoil" placeholder={__('register.inputs.name.placeholder')} value={data.name ?? ''} onChange={(e) => setDataField('name', e.target.value)} />
											{ errors['name'] && <div className="invalid-feedback d-block">{errors['name'][0]}</div> }
										</div>
									}
									<div className="mb-2">
										<label className="label-plenoil">{__('register.inputs.vatnumber.label')}</label>
										<input 
											type="text" 
											className="input-plenoil" 
											placeholder={__('register.inputs.vatnumber.placeholder')} 
											value={data.vatnumber ?? ''} 
											onChange={(e) => setDataField('vatnumber', e.target.value)} 
											onBlur={(e) => checkExistingVatnumber(e.target.value)}
										/>
										{ errors['vatnumber'] && <div className="invalid-feedback d-block">{errors['vatnumber'][0]}</div> }
									</div>
									<div className="mb-2">
										<label className="label-plenoil">{__('register.inputs.email.label')}</label>
										<input type="email" className="input-plenoil" placeholder={__('register.inputs.email.placeholder')} value={data.email ?? ''} onChange={(e) => setDataField('email', e.target.value)} />
										{ errors['email'] && <div className="invalid-feedback d-block">{errors['email'][0]}</div> }
									</div>
									<div className="mb-2">
										<label className="label-plenoil">{__('register.inputs.repeatemail.label')}</label>
										<input type="email" className="input-plenoil" placeholder={__('register.inputs.repeatemail.placeholder')} value={data.email_confirmation ?? ''} onChange={(e) => setDataField('email_confirmation', e.target.value)} />
										{ errors['email_confirmation'] && <div className="invalid-feedback d-block">{errors['email_confirmation'][0]}</div> }
									</div>
									<div className="mb-2">
										<label className="label-plenoil">{__('register.inputs.password.label')}</label>
										<input type="password" className="input-plenoil" placeholder={__('register.inputs.password.placeholder')} value={data.password ?? ''} onChange={(e) => setDataField('password', e.target.value)} />
										{ errors['password'] && <div className="invalid-feedback d-block">{errors['password'][0]}</div> }
									</div>

									{ !vatnumberExists &&
										<React.Fragment>
											<h2 className="mt-4">{__('register.title.address')}</h2>
											<div className="mb-2">
												<label className="label-plenoil">{__('register.inputs.phone.label')}</label>
												<input type="text" className="input-plenoil" placeholder={__('register.inputs.phone.placeholder')} value={data.phone ?? ''} onChange={(e) => setDataField('phone', e.target.value)} />
												{ errors['phone'] && <div className="invalid-feedback d-block">{errors['phone'][0]}</div> }
											</div>
											<div className="mb-2">
												<label className="label-plenoil">{__('register.inputs.province.label')}</label>
												<select className="input-plenoil" placeholder={__('register.inputs.province.label')} value={data.province_id ?? ''}  onChange={(e) => setDataField('province_id', e.target.value)}>
													<option value="">{__('register.inputs.province.placeholder')}</option>
													{provinces?.map((el, idx) => {
														return (
															<option value={el.id} key={idx}>{el.name}</option>
														)	
													})}
												</select>
												{ errors['province_id'] && <div className="invalid-feedback d-block">{errors['province_id'][0]}</div> }
											</div>
											<div className="mb-2">
												<label className="label-plenoil">{__('register.inputs.city.label')}</label>
												<input type="text" className="input-plenoil" placeholder={__('register.inputs.city.placeholder')} value={data.city ?? ''} onChange={(e) => setDataField('city', e.target.value)} />
												{ errors['city'] && <div className="invalid-feedback d-block">{errors['city'][0]}</div> }	
											</div>
											<div className="mb-2">
												<label className="label-plenoil">{__('register.inputs.address.label')}</label>
												<input type="text" className="input-plenoil" placeholder={__('register.inputs.address.placeholder')} value={data.address ?? ''} onChange={(e) => setDataField('address', e.target.value)} />
												{ errors['address'] && <div className="invalid-feedback d-block">{errors['address'][0]}</div> }			
											</div>
											<div className="mb-2">
												<label className="label-plenoil">{__('register.inputs.postalcode.label')}</label>
												<input 
													type="text" 
													className="input-plenoil" 
													placeholder={__('register.inputs.postalcode.placeholder')} 
													value={data.postalcode ?? ''}
													onChange={(e) => {
														setDataField('postalcode', e.target.value)
														postalCodeCheck(e.target.value);
													}} 
												/>
												{ errors['postalcode'] && <div className="invalid-feedback d-block">{errors['postalcode'][0]}</div> }
											</div>
										</React.Fragment>
									}
								</div>
							</div>

							{ !vatnumberExists &&
								<React.Fragment>
									<div className="mt-4">
										<h2>{__('register.title.invoices')}</h2>
										
										<div id="invoicing-options">
											<label className="label-plenoil mb-0"><input type="radio" className="me-2" checked={data.invoices_period === 'individual'} onChange={(e) => setDataField('invoices_period', 'individual')} /> {__('register.inputs.invoices.porsuministros.label')}</label> 
											<label className="label-plenoil mb-0"><input type="radio" className="ms-2 me-2" checked={data.invoices_period === 'mensual'} onChange={(e) => setDataField('invoices_period', 'mensual')} /> {__('register.inputs.invoices.mensuales.label')}</label>
										</div>
										<div id="invoicing-help">
											<div><b>{__('register.inputs.invoices.porsuministros.label')}:</b> {__('register.inputs.invoices.porsuministros.text')}</div>
											<div><b>{__('register.inputs.invoices.mensuales.label')}:</b> {__('register.inputs.invoices.mensuales.text')}</div>
										</div>
									</div>


									<div className="mt-5" id="vehicles">
										<h2>{__('register.title.matriculas')}</h2>
										
										<div className="row">
											<div className="col-md-8">
												{data.vehicles?.map((el, idx) => {
													return (
														<div className="vehicle-row" key={idx}>
															<button onClick={() => removeVehicle(idx)}><i className="bi bi-x"></i></button>
															<input type="text" className="input-plenoil" placeholder={__('register.inputs.matriculas.input.placeholder')} value={el ?? ''} onChange={(e) => setVehicle(idx, e.target.value)} />
														</div>
													)
												})}
											</div>
											<div className="col-md-1"></div>
											<div className="col-md-3">
												<button className="btn-plenoil btn-plenoil-blue2 outline w-100 d-flex justify-space-betweeen" onClick={(el) => addVehicle()}>{__('register.inputs.matriculas.addVehicleButton')} <i className="bi bi-plus ms-auto"></i></button>
											</div>
										</div>
									</div>
								</React.Fragment>
							}


							<div id="bottom">
								<label>
									<input type="checkbox" checked={policyAccepted} onChange={(e) => setPolicyAccepted(e.target.checked)} />
									{__('register.policy.text1')}&nbsp;<a href="https://plenoil.es/politica-de-privacidad-plenoil/" className="privacy-link" target="_blank" rel="noreferrer">{__('register.policy.text2')}</a>
								</label>
								<div className="mt-4">
									<button className="btn-plenoil btn-plenoil-blue2 w-100" onClick={() => register()} disabled={!policyAccepted}>{__('register.createAccountButton')}</button>
								</div>
								<div className="mt-3">
									<NavLink to="/login" id="cancel">{__('register.cancelButton')}</NavLink>
								</div>
							</div>
						</React.Fragment>
					}

					{ registrationCompleted &&
						<React.Fragment>
							<div className="text-center mt-2">
								<img id="user" className="mb-4" src={assets('/assets/img/cliente-inicio-redondo.svg')} alt="user" />

								<h2>{__('register.registrationCompleted.title')}</h2>
								<p>{__('register.registrationCompleted.text')}</p>
								<br />

								<NavLink to="/login" className="btn-plenoil btn-plenoil-blue2 me-3" style={{textDecoration: 'none'}}>{__('register.registrationCompleted.verifiedButton')}</NavLink>
								<button className="btn-plenoil btn-plenoil-orange" onClick={() => resendConfirmationEmail()}>{__('register.registrationCompleted.resendEmailButton')}</button>
							</div>
						</React.Fragment>
					}
				</div>
			</RegisterStyled>
		</NotLoggedLayout>
	);
}