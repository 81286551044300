import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { assets } from 'helpers/generic';
import Statistics from './Statistics';
import UserMeta from './UserMeta';
import Station from './Station';
import { __ } from 'helpers/i18n';

const DashboardContainer = styled.div` 

`;

const ButtonsWrapper = styled.div` 
	display: grid;
	grid-template-columns: minmax(100px, 180px) minmax(100px, 180px);
	justify-content: center;
	width: 100%;
	margin-top: 15px;
`;

const Button = styled.a` 
	display: flex;
	margin: 10px;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	background: var(--plenoil-white);
	border-radius: 30px;
	padding: 30px 20px;
	text-decoration: none;
	color: var(--plenoil-black);
	box-shadow: var(--plenoil-shadow);
	font-size: 15px;
	line-height: 100%;
	font-weight: 500;
	cursor: pointer;

	img {
		max-width: 45px;
		max-height: 45px;
		margin-bottom: 15px;
	}
	
	div {
		display: flex;
		align-items: center;
		height: 100%;
	}

	&:hover {
		color: white;
		background: ${props => props.hoverBackground};

		img {
			filter: brightness(0) invert(1);
		}
	}
`;

export default function Dashboard() {
	const navigate = useNavigate();

	return (
		<LoggedLayout>
			<DashboardContainer>
				<UserMeta />
				<Station />
				<ButtonsWrapper>
					<Button onClick={() => navigate('/consumptions')} hoverBackground="var(--plenoil-orange)">
						<img src={assets('assets/img/icono-consumos.svg')} alt={__('dashboard.link.consumptions')} />
						<div>{__('dashboard.link.consumptions')}</div>
					</Button>
					<Button onClick={() => navigate('/refuelings')} hoverBackground="var(--plenoil-green2)">
						<img src={assets('assets/img/icono-repostajes.svg')} alt={__('dashboard.link.refuelings')} />
						<div>{__('dashboard.link.refuelings')}</div>
					</Button>
					<Button onClick={() => navigate('/invoices')} hoverBackground="var(--plenoil-blue2)">
						<img src={assets('assets/img/icono-facturas.svg')} alt={__('dashboard.link.invoices')} />
						<div>{__('dashboard.link.invoices')}</div>
					</Button>
					<Button onClick={() => navigate('/account')} hoverBackground="var(--plenoil-yellow2)">
						<img src={assets('assets/img/icono-datos.svg')} alt={__('dashboard.link.account')} />
						<div>{__('dashboard.link.account')}</div>
					</Button>
				</ButtonsWrapper>

				<Statistics />
			</DashboardContainer>
		</LoggedLayout>
	);
}


