import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { assets } from 'helpers/generic';



const ButtonStyled = styled.div` 
	cursor: pointer;

    font-size: 20px;
    margin-left: 10px;
    width: 30px;

    img {
        width: 20px;
        height: 20px;
    }
`;

export default function BackButton(props) {
    let location = useLocation();
    let navigate = useNavigate();

    let isHome = location.pathname === '/';

    const go = () => {
        if ( isHome ) {
            navigate('/');
        } else {
            navigate(-1);
        }
    }

	return (
		<ButtonStyled id="btn-home-back" onClick={(e) => go()} isHome={isHome}>
			<img src={assets('/assets/img/'+(isHome ? 'inicio' : 'flecha_atras')+'.svg')} alt="btn-back-home" />
		</ButtonStyled>
	);
}


