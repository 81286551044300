import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Device } from '@capacitor/device';
import { default as UserService } from 'services/User';

let localNotificationsPermissions = false;

const PushHandler = () => {
    let navigate = useNavigate();
    let navigateRef = useRef(navigate);

    useEffect(() => {
		// Request permission to use push notifications
		// iOS will prompt user and return if they granted permission or not
		// Android will just grant without prompting
        PushNotifications.checkPermissions().then(permissions => {
            if ( permissions.display !== 'granted' ) {
                PushNotifications.requestPermissions(result => {
                    if (result.receive === 'granted') PushNotifications.register();
                });
            } else {
                PushNotifications.register();
            }
        });

		// On success, we should be able to receive notifications
		PushNotifications.addListener('registration', async (token) => {
            console.info('Push registration success', token.value);

            let device = await Device.getInfo();
            await UserService.updatePushToken(token.value, device.platform);
        });

		// Some issue with our setup and push will not work
		PushNotifications.addListener('registrationError', (error) => {
            console.error('Error on registration', error);
        });

		// Show the notification payload if the app is open on our device
		PushNotifications.addListener('pushNotificationReceived', (notification) => {
            console.info('Push received', notification);

            // Prepare attachments
            let attachments = [];
            if ( notification.data ) {
                for(let idx in notification.data) {
                    attachments.push({
                        id: idx,
                        data: notification.data[idx] ?? undefined
                    });
                }
            }


            // Local notification
                if ( !localNotificationsPermissions ) {
                    console.log('Local notifications permissions not granted');
                    return;
                }

                // Generate random int 24 bits
                let max24 = Math.pow(2, 24) - 1
                let id = Math.floor(Math.random() * max24);

                // Send
                LocalNotifications.schedule({
                    notifications: [
                        {
                            title: notification.title,
                            body : notification.body,
                            largeBody: notification.body,
                            id: id,
                            attachments: attachments,
                            actionTypeId: '',
                        },
                    ],
                });
        });

		// Method called when tapping on a notification
		PushNotifications.addListener('pushNotificationActionPerformed', (e) => {
				console.log('Push action performed: ', e);

                navigateRef.current('/messages');
			}
		);

        // Check permissions for local notifications
        LocalNotifications.checkPermissions().then(permissions => {
            if ( permissions.display !== 'granted' ) {
                LocalNotifications.requestPermissions(result => {
                    if (result.display === 'granted') localNotificationsPermissions = true;
                });
            } else {
                localNotificationsPermissions = true;
            }
        });

		// Method called when tapping on a local notification
		LocalNotifications.addListener('localNotificationActionPerformed', (e) => {
			console.log('Local action performed: ', e);

			// If click on notification
			if ( e.actionId === "tap" ) {
				navigateRef.current('/messages');
			}
		});

		return function cleanup() {
			PushNotifications.removeAllListeners();
			LocalNotifications.removeAllListeners();
		}
	}, []);

    return(<></>)
};

export default PushHandler;