const lang = {
    'langOrder': 1,
    'langCode': 'es',
    'langName': 'Español',

	/**
	 * layouts/HamburguerMenu.js
	 */
	'hamburguerMenu.links.stations': 'Gasolineras',
	'hamburguerMenu.links.consumptions': 'Consumos',
	'hamburguerMenu.links.refuelings': 'Repostajes',
	'hamburguerMenu.links.invoices': 'Facturas',
	'hamburguerMenu.links.messages': 'Mensajes',
	'hamburguerMenu.links.account': 'Mis datos',
	'hamburguerMenu.logoutButton': 'Cerrar sesión',
	'hamburguerMenu.deleteAccountButton': 'Eliminar cuenta',
	'hamburguerMenu.deleteAccountPopup.title': 'Introduzca su contraseña para eliminar su cuenta',
	'hamburguerMenu.deleteAccountPopup.deleteButton': 'ELIMINAR',
	'hamburguerMenu.deleteAccountPopup.privacyLink': 'Términos de uso y Política de privacidad',
	'hamburguerMenu.deleteAccountPopup.confirmMessage': '¿Está seguro que desea continuar?',

	/**
	 * layouts/LoggedLayout.js
	 */
	'loggedlayout.bottomMenu.stations': 'Gasolineras',
	'loggedlayout.bottomMenu.consumptions': 'Consumos',
	'loggedlayout.bottomMenu.refuelings': 'Repostajes',
	'loggedlayout.bottomMenu.invoices': 'Facturas',
	'loggedlayout.bottomMenu.messages': 'Mensajes',
	'loggedlayout.bottomMenu.account': 'Mis datos',

	/**
	 * pages/Splash.js
	 */
	'splash.langselector.label': 'Seleccionar idioma',
    'splash.animation.text1': 'Energía al mejor',
    'splash.animation.text2': 'precio para su vehículo',
    'splash.mapbutton': 'VER GASOLINERAS Y PRECIOS',
    'splash.loginbutton': 'INICIAR SESIÓN',
    'splash.ask': '¿Aún no tienes una cuenta en Plenoil?',
    'splash.createaccountbutton': 'Crear cuenta',
    'splash.policylink': 'Términos de uso y Política de privacidad',

	/**
	 * pages/Login.js
	 */
	'login.recoverpasswordthrottle': 'Has intentado solicitar una nueva contraseña demasiado rápido. Espera 1 minuto para intentarlo de nuevo.',
	'login.invalidlogin': 'Datos de acceso inválidos',
	'login.recoverpasswordemailsent': 'Te hemos enviado un email con las instrucciones para recuperar tu contraseña',
	'login.loginForm.input.user': 'NIF/CIF (sin espacios)',
	'login.loginForm.input.password': 'CONTRASEÑA',
	'login.loginForm.loginbutton': 'INICIAR SESIÓN',
	'login.loginForm.lostpasswordButton': 'He olvidado la contraseña',
	'login.recoverPasswordForm.text1' : 'Introduce tu NIF/CIF para recibir un email con las',
	'login.recoverPasswordForm.text2' : 'instrucciones para recuperar tu contraseña',
	'login.recoverPasswordForm.usernameInput': 'NIF/CIF',
	'login.recoverPasswordForm.button': 'SOLICITAR NUEVA CONTRASEÑA',
	'login.recoverPasswordForm.gobackbutton': 'Volver atrás',
	'login.noaccountask': '¿Aún no tienes una cuenta en Plenoil?',
	'login.createaccountbutton': 'Crear cuenta',
	'login.privacylink': 'Términos de uso y Política de privacidad',

    /**
	 * pages/Logout.js
	 */
	'logout.text': 'Cerrando sesión...',

    /**
	 * pages/Register.js
	 */
	'register.registerErrorMessage': 'Por favor, corrige los errores antes de continuar',
	'register.genericErrorMessage': 'Ha ocurrido un error, por favor inténtelo de nuevo',
	'register.confirmationMessageResent' : 'Mensaje de confirmación enviado de nuevo',
	'register.title.userData': 'Datos de usuario',
	'register.inputs.name.label': 'Nombre y apellidos / Nombre empresa',
	'register.inputs.name.placeholder': 'Nombre Empresa SL',
	'register.inputs.vatnumber.label': 'CIF/NIF',
	'register.inputs.vatnumber.placeholder': 'XXXXXXXXX',
	'register.inputs.email.label': 'Email',
	'register.inputs.email.placeholder': 'email@ejemplo.com',
	'register.inputs.repeatemail.label': 'Repetir email',
	'register.inputs.repeatemail.placeholder': 'email@ejemplo.com',
	'register.inputs.password.label': 'Contraseña (mínimo 6 digitos)',
	'register.inputs.password.placeholder': '******',
	'register.title.address': 'Dirección',
	'register.inputs.phone.label': 'Teléfono',
	'register.inputs.phone.placeholder': 'XXXXXXXXX',
	'register.inputs.province.label': 'Provincia',
	'register.inputs.province.placeholder': 'Tu provincia',
	'register.inputs.city.label': 'Localidad',
	'register.inputs.city.placeholder': 'Tu localidad',
	'register.inputs.address.label': 'Dirección',
	'register.inputs.address.placeholder': 'C/ Ejemplo, 10, 1A',
	'register.inputs.postalcode.label': 'Código postal',
	'register.inputs.postalcode.placeholder': 'XXXXX',
	'register.title.invoices': 'FACTURAS',
	'register.inputs.invoices.porsuministros.label': 'POR SUMINISTRO',
	'register.inputs.invoices.mensuales.label': 'MENSUALES',
	'register.inputs.invoices.porsuministros.text': 'recibirá una factura por cada repostaje',
	'register.inputs.invoices.mensuales.text': 'recibirá una factura mensual con todos los repostajes',
	'register.title.matriculas': 'Matrículas',
	'register.inputs.matriculas.input.placeholder': 'Introduzca matrícula',
	'register.inputs.matriculas.addVehicleButton': 'Añadir vehículo',
	'register.policy.text1': 'He leído y acepto la',
	'register.policy.text2': 'Política de privacidad',
	'register.createAccountButton': 'CREAR CUENTA',
	'register.cancelButton': 'Cancelar',
	'register.registrationCompleted.title': 'Cuenta creada',
	'register.registrationCompleted.text': 'En breve recibirás un email para verificar tu cuenta',
	'register.registrationCompleted.verifiedButton': 'Ya he verificado',
	'register.registrationCompleted.resendEmailButton': 'Reenviar correo',

	/**
	 * pages/Account/index.js
	 */
	'account.index.title': 'MIS DATOS',
	'account.index.concentrador.onprogressmessage': 'Actualización en progreso. En breve estará disponible. Puedes seguir navegando.',
	'account.index.inputs.name': 'Nombre y apellidos / Nombre empresa',
	'account.index.inputs.email': 'Email',
	'account.index.inputs.phone': 'Teléfono',
	'account.index.inputs.address': 'Dirección',
	'account.index.inputs.province': 'Provincia',
	'account.index.inputs.city': 'Localidad',
	'account.index.inputs.postalcode': 'Código postal',
	'account.index.editButton': 'Editar datos',
	'account.index.editInvoicingButton': 'Editar facturación',
	'account.index.favoriteStation.placeholder': 'Gasolinera favorita',

	/**
	 * pages/Account/UserEdit.js
	 */
	'account.userEdit.successMessage': 'Datos recibidos',
	'account.userEdit.errorMessage': 'Error al recibir los datos',
	'account.userEdit.title': 'DATOS DE USUARIO',
	'account.userEdit.concentrador.onprogressmessage': 'Actualización en progreso. En breve estará disponible. Puedes seguir navegando.',
	'account.userEdit.input.name.label': 'Nombre y apellidos / Nombre empresa',
	'account.userEdit.input.name.placeholder': 'Nombre Empresa SL',
	'account.userEdit.input.phone.label': 'Teléfono',
	'account.userEdit.input.phone.placeholder': 'XXXXXXXXX',
	'account.userEdit.input.email.label': 'Email',
	'account.userEdit.input.email.placeholder': 'email@ejemplo.com',
	'account.userEdit.input.address.label': 'Dirección',
	'account.userEdit.input.address.placeholder': 'C/ Ejemplo, 10, 1A',
	'account.userEdit.input.province.label': 'Provincia',
	'account.userEdit.input.province.placeholder': 'Tu provincia',
	'account.userEdit.input.city.label': 'Ciudad',
	'account.userEdit.input.city.placeholder': 'Tu localidad',
	'account.userEdit.input.postalcode.label': 'Código postal',
	'account.userEdit.input.postalcode.placeholder': 'XXXXXX',
	'account.userEdit.input.password.label': 'Contraseña',
	'account.userEdit.input.password.placeholder': '******',
	'account.userEdit.saveButton': 'GUARDAR',
	'account.userEdit.cancelButton': 'CANCELAR',

	/**
	 * pages/Account/InvoicingEdit.js
	 */
	'account.invoicingEdit.successMessage': 'Datos recibidos',
	'account.invoicingEdit.errorMessage': 'Error al recibir los datos',
	'account.invoicingEdit.title': 'FACTURACIÓN',
	'account.invoicingEdit.concentrador.onprogressmessage': 'Actualización en progreso. En breve estará disponible. Puedes seguir navegando.',
	'account.invoicingEdit.porsuministro.label': 'POR SUMINISTRO',
	'account.invoicingEdit.porsuministro.text': 'recibirá una factura por cada repostaje.',
	'account.invoicingEdit.mensuales.label': 'MENSUALES',
	'account.invoicingEdit.mensuales.text': 'recibirá una factura mensual con todos los repostajes.',
	'account.invoicingEdit.vehicles.title': 'Vehículos',
	'account.invoicingEdit.vehicles.placeholder': 'Introduzca matrícula',
	'account.invoicingEdit.vehicles.addButton': 'Añadir vehículo',
	'account.invoicingEdit.saveButton': 'GUARDAR',
	'account.invoicingEdit.cancelButton': 'CANCELAR',

	/**
	 * pages/Consumptions/index.js
	 */
	'consumptions.title': 'CONSUMOS',
	'consumptions.mensualReport.thismonth': 'este mes',
	'consumptions.mensualReport.liters': 'LITROS',
	'consumptions.mensualReport.lastRefueling': 'Último repostaje',
	'consumptions.barsReportFilters.matriculas.all': 'TODAS',
	'consumptions.toggleButton.matriculas': 'Ver por matrícula',
	'consumptions.toggleButton.resumen': 'Ver resumen',

	/**
	 * pages/Consumptions/BarsReport.js
	 */
	'consumptions.barsReport.empty': 'No hay consumos',
	'consumptions.barsReport.totalYear': 'CONSUMO TOTAL ANUAL',

	/**
	 * pages/Consumptions/PieReport.js
	 */
	'consumptions.pieReport.empty': 'No hay consumos',

	/**
	 * pages/Dashboard/index.js
	 */
	'dashboard.link.consumptions': 'CONSUMOS',
	'dashboard.link.refuelings': 'REPOSTAJES',
	'dashboard.link.invoices': 'FACTURAS',
	'dashboard.link.account': 'MIS DATOS',

	/**
	 * pages/Dashboard/Station.js
	 */
	'dashboard.station.station': 'GASOLINERA',
	'dashboard.station.favorite': 'FAVORITA',
	'dashboard.station.closest': 'MÁS CERCANA',
	'dashboard.station.mapButton': 'VER',
	'dashboard.station.diesel': 'DIESEL',
	'dashboard.station.sp95': 'SP95',

	/**
	 * pages/Dashboard/Statistics.js
	 */
	'dashboard.statistics.lastRefueling': 'ÚLTIMO REPOSTAJE',
	'dashboard.statistics.monthSummary': 'RESUMEN DEL MES',

	/**
	 * pages/Dashboard/UserMeta.js
	 */
	'dashboard.userMeta.welcome': 'Bienvenido',

	/**
	 * pages/Invoices/index.js
	 */
	'invoices.title': 'FACTURAS',
	'invoices.table.date': 'FECHA',
	'invoices.table.number': 'NÚMERO',
	'invoices.table.amount': 'IMPORTE',
	'invoices.table.invoice': 'FACTURA',
	'invoices.table.empty': 'No hay facturas',
	'invoices.selectAllButton': 'Seleccionar todo',
	'invoices.unselectButton': 'Deseleccionar',
	'invoices.downloadButton': 'Descargar facturas',

	/**
	 * pages/Messages/index.js
	 */
	'messages.title': 'MENSAJES',
	'messages.empty': 'Todavía no tienes mensajes',
	'messages.loadMoreButton': 'Cargar más',

	/**
	 * pages/Refuelings/index.js
	 */
	'refuelings.title': 'CONSUMOS',
	'refuelings.table.date': 'FECHA',
	'refuelings.table.station': 'ESTACIÓN',
	'refuelings.table.amount': 'IMPORTE',
	'refuelings.table.ticket': 'TICKET',
	'refuelings.table.empty': 'No hay repostajes',

	/**
	 * pages/Refuelings/TicketPreview.js
	 */
	'refuelings.ticketpreview.downloadButton': 'Descargar ticket',

	/**
	 * pages/Station/index.js
	 */
	'station.title': 'NUESTRAS GASOLINERAS',
	'station.electriccharger': 'RECARGA',
	'station.adblue': 'ADBLUE',
	'station.diesel': 'DIESEL',
	'station.sp95': 'SP95',
	'station.mapButton': 'VER',
	'station.open24hours': 'Abierto 24 horas',
	'station.personalized': 'Atención personalizada',

	/**
	 * pages/StationsList/index.js
	 */
	'stationslist.title': 'NUESTRAS GASOLINERAS',
	'stationslist.ubication': 'Ubicación',


	/**
	 * pages/Wash/index.js
	 */
	'wash.mapButton': 'VER',
	'wash.backButton': 'atrás',
	'wash.minutes': 'minutos de lavado',
	'wash.lavallantas.text1': 'AGUA DESCALCIFICADA FRÍA + DETERGENTE',
	'wash.lavallantas.text2': 'SITÚE LA LANZA A 15-20CM DEL VEHÍCULO',
	'wash.lavallantas.title': 'LAVA LLANTAS',
	'wash.prelavado.text1': 'AGUA DESCALCIFICADA FRÍA + ESPUMA NEÓN',
	'wash.prelavado.text2': 'SITÚE LA LANZA A 15-20CM DEL VEHÍCULO',
	'wash.prelavado.title': 'PRELAVADO',
	'wash.lavado.text1': 'AGUA DESCALCIFICADA CALIENTE + DETERGENTE',
	'wash.lavado.text2': 'SITÚE LA LANZA A 15-20CM DEL VEHÍCULO',
	'wash.lavado.title': 'LAVADO',
	'wash.aclarado.text1': 'AGUA DESCALCIFICADA FRÍA',
	'wash.aclarado.text2': 'SITÚE LA LANZA A 25-35CM DEL VEHÍCULO',
	'wash.aclarado.title': 'ACLARADO',
	'wash.superencerado.text1': 'AGUA OZMOTIZADA FRÍA + CERA',
	'wash.superencerado.text2': 'SITÚE LA LANZA A 25-35CM DEL VEHÍCULO',
	'wash.superencerado.title': 'SÚPER ENCERADO',
	'wash.superencerado2.text1': 'AGUA OZMOTIZADA FRÍA + CERA',
	'wash.superencerado2.text2': 'SITÚE LA LANZA A 50CM DEL VEHÍCULO',
	'wash.superencerado2.title': 'SÚPER ENCERADO',
	'wash.abrillantado.text1': 'AGUA OZMOTIZADA FRÍA',
	'wash.abrillantado.text2': 'SITÚE LA LANZA A 25-35CM DEL VEHÍCULO',
	'wash.abrillantado.title': 'ABRILLANTADO',
	'wash.abrillantado2.text1': 'AGUA OZMOTIZADA FRÍA',
	'wash.abrillantado2.text2': 'SITÚE LA LANZA A 50CM DEL VEHÍCULO',
	'wash.abrillantado2.title': 'ABRILLANTADO',

	/**
	 * pages/Map/index.js
	 */
	'map.title': 'NUESTRAS GASOLINERAS',
	'map.viewlistbutton': 'LISTADO DE GASOLINERAS',

	/**
	 * pages/Map/CustomMarker.js
	 */
	'map.customMarker.stationButton': 'Ver Estación',
	'map.customMarker.mapButton': 'Cómo llegar',
	'map.customMarker.diesel': 'diesel',
	'map.customMarker.sp95': 'sin plomo 95',

	/**
	 * pages/Map/TopFilters.js
	 */
	'map.topFilters.filterByProvince': 'Filtro por provincia',
}

export default lang;