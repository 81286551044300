import React from "react";
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    
`;

const StyledLayout = styled.div`
    position: fixed;
	top: 0;
	min-width: 350px;
	max-width: 600px;
	height: 100%;
	margin: 0 auto;
	background: white;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;  


`;

const Content = styled.div` 
    position: relative;
    min-width: 350px;
	max-width: 600px;
	height: 100%;
    margin: 0 auto;
    overflow-y: auto;
	overflow-x: hidden;
`;

const Version = styled.div`
	position: absolute;
	top: 0;
	right: 10px;
	font-size: 9px;
`;

export default function NotLoggedLayout(props) {

	return (
        <React.Fragment>
            <GlobalStyle />
            <StyledLayout>
				<Version>v2.0.2</Version>
                <Content className="container">{props.children}</Content>
            </StyledLayout>
        </React.Fragment>
	);
}