import React from 'react';
import styled from 'styled-components';
import { PieChart } from 'react-minimal-pie-chart';
import { formatNumber, assets } from 'helpers/generic'; 
import { __ } from 'helpers/i18n';

const PieReportStyled = styled.div`
	position: relative;

	.pieChart{
		margin: 0 auto;
		margin-top: 20px;
		width: 230px;
		height: 230px;
		text-align: center;
	}

	#empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 50%;
		top: 0px;
		width: 200px;
		margin-left: -100px;
		color: var(--plenoil-gray2);
		font-weight: 500;
		font-size: 16px;

		img {
			width: 60%;
			margin-top: 100px;
		}
	}

	.legends {
		letter-spacing: 0px;
		margin-top: 10px;
		text-align: center;

		.legend {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 10px;
			margin-right: 20px;

			.square {
				width: 14px;
				height: 14px;
				border-radius: 50%;
			}

			.matricula {
				color: var(--plenoil-black);
				font-weight: bold;
				padding-top: 2px;
				text-align: right;
				padding-left: 5px;
				font-size: 14px;

				span {
					font-size: 15px;
				}
			}
		}
	}
`;

export default function PieReport(props) {
	let statistics = props.statistics;

	// Prepare pie data
	let pieData = [];
	let legends = [];
	let pieColors = ['var(--plenoil-orange)', 'var(--plenoil-orange2)', 'var(--plenoil-yellow)', 'var(--plenoil-blue2)', 'var(--plenoil-blue)', 'var(--plenoil-black)'];
	let counter = 0;
	for(let i in statistics) {
		pieData.push({
			title: i,
			value: statistics[i],
			color: pieColors[counter]
		});
		legends.push(
			<div key={i} className="legend">
				<div className="square" style={{background: pieColors[counter]}}></div>
				<div className="matricula">{i}: {formatNumber(statistics[i], 2, true, true)}<span>€</span></div>
			</div>
		);

		// Counter
		if ( counter === pieColors.length - 1 ) counter = 0;
		counter++;
	}		

	// Render
	return (
		<PieReportStyled>
			<div className="pieChartWrapper">
				<div className="pieChart">
					{ pieData.length > 0 &&
						<PieChart
							data={pieData}
							radius={45}
						  	lineWidth={40}
      						animate
						/>
					}
					{ pieData.length <= 0 &&
						<div id="empty">
							<img src={assets('assets/img/icono-no-vehiculo.svg')} alt="no hay consumos" />
							{__('consumptions.pieReport.empty')}
						</div>
					}
				</div>
				<div className="legends">
					{ legends.length > 0 &&
						legends
					}
				</div>
			</div>
		</PieReportStyled>
	);
}
