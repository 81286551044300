import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { assets, formatNumber } from 'helpers/generic';
import { default as UserService } from 'services/User';
import { default as StationService } from 'services/Station';
import { Geolocation } from '@capacitor/geolocation';
import { __ } from 'helpers/i18n';
import OpenMapButton from 'components/OpenMapButton';

const StationStyled = styled.div` 
	position: relative;

	.top {
		width: 100%;
		height: 55%;
		font-size: 12px;
		text-align: center;
		margin-top: 10px;
		margin-bottom: 10px;
		font-weight: 500;
	}

	.prices {
		width: 100%;
		height: 80px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > div {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			color: white;
			width: 50%;
			height: 100%;
			border-radius: 5px;
			font-weight: 500;

			div.name {
				font-size: 18px;
				line-height: 10px;
				margin-top: 8px;
			}

			div.price {
				font-size: 24px;
			}
		}

		& > .goa {
			background: var(--plenoil-black);
			margin-right: 5px;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}

		& > .sp95 {
			background: var(--plenoil-green);
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
		}
	}

	.details {
		display: flex;
		align-items: center;
		width: 200px;
		margin: 0 auto;
		margin-top: -8px;
		margin-bottom: 10px;

		.left {
			margin-right: 10px;
			text-decoration: none;

			& > div {
				text-decoration: none;
			}
	
			button {
				padding: 0;
				color: var(--bs-gray-700);
				font-size: 10px;
				margin-top: 2px;
				width: 30px;

				img {
					width: 20px;
				}
			}
		}

		.right {
			.name {
				font-size: 17px;
				line-height: 17px;
				font-weight: 700;
			}

			.address {
				font-size: 14px;
				line-height: 14px;
				font-weight: 500;
			}
		}
	}
`;

export default function Station() {
	let [station, setStation] = useState(undefined);

	useEffect(() => {
		const getData = async () => {
			let userData = await UserService.getData();
			if ( userData?.favorite_station ) {
				setStation({
					id: userData?.favorite_station?.id,
					name: userData?.favorite_station?.name,
					goa: userData?.favorite_station?.oilDiesel,
					sp95: userData?.favorite_station?.oil95,
					address: userData?.favorite_station?.street,
					latitude: userData?.favorite_station?.latitude,
					longitude: userData?.favorite_station?.longitude,
					favorite: true
				})
			} else {
				setStation(false); // This triggers "get closest" on next useEffect
			}
		}
		getData();
	}, []);

	useEffect(() => {
		// Only get closest if not favorite
		if ( station !== false ) return;

		const getClosestStation = async () => {
			let coordinates = null;
			try {
				coordinates = await Geolocation.getCurrentPosition();
			} catch (e) {}
			if ( !coordinates?.coords?.latitude || !coordinates?.coords?.longitude ) return;

			let closestStation = await StationService.getClosestByLL(coordinates?.coords?.latitude, coordinates?.coords?.longitude);
			if ( closestStation?.id ) {
				setStation({
					id: closestStation?.id,
					name: closestStation?.name,
					goa: closestStation?.oilDiesel,
					sp95: closestStation?.oil95,
					address: closestStation?.street,
					latitude: closestStation?.latitude,
					longitude: closestStation?.longitude,
					closest: true
				});
			}
		}
		getClosestStation();
		
	}, [station])

	return (
        <StationStyled>
			{ station?.id && 
				<React.Fragment>
					<div className="top">
						{__('dashboard.station.station')} {station?.favorite ? __('dashboard.station.favorite') : ''} {station?.closest ? __('dashboard.station.closest') : ''}
					</div>
					<div className="details">
						<div className="left">
							<OpenMapButton className="btn btn-sm btn-link" latitude={station?.latitude} longitude={station?.longitude}>
								<img src={assets('/assets/img/ICONOS-MAPA.svg')} alt="ubication" />	
								{__('dashboard.station.mapButton')}
							</OpenMapButton>
						</div>
						<div className="right">
							<div className="name">
								{station?.name ?? '-'}
							</div>
							<div className="address">
								{station?.address ?? '-'}
							</div>
						</div>
					</div>
					<div className="prices">
						<div className="goa">
							<div className="name">{__('dashboard.station.diesel')}</div>
							<div className="price">{station?.goa ? formatNumber(station.goa, 3, true, true) : '-,---'} €/L</div>
						</div>
						<div className="sp95">
							<div className="name">{__('dashboard.station.sp95')}</div>
							<div className="price">{station?.sp95 ? formatNumber(station.sp95, 3, true, true) : '-,---'} €/L</div>
						</div>
					</div>
				</React.Fragment>
			}			
        </StationStyled>
	);
}


