import React, { useEffect, useState } from 'react';
import { Device } from '@capacitor/device';
import styled from 'styled-components';
import { openMapByDevice } from 'helpers/generic';


const ButtonStyled = styled.div` 
	button {
        all: unset;
    }

    .dropdown {
        all: unset;

        padding: 0 !important;

        .dropdown-menu {
            display: none;
            padding: 10px;
            background: white;
            list-style: none;
            border-radius: 5px;
            z-index: 9999999 !important;
            
            li {
                display: flex;

                a {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    text-decoration: none;
                    color: inherit;
                    color: #333;
                    font-size: 14px;
                    padding: 5px 10px;
                    border-radius: 5px;

                    &:active {
                        background-color: inherit;
                    }
                }
            }

            &.show {
                display: block;
            }
        }
    }
`;

export default function OpenMapButton(props) {
    let parentProps = {...props};
    delete parentProps.children;

    const latitude = props.latitude;
    const longitude = props.longitude;
    
	let [platform, setPlatform] = useState(undefined);

    useEffect(() => {
		let getPlatform = async () => {
			const info = await Device.getInfo();
			setPlatform(info.platform);
		}
		getPlatform();
	}, []);

    const openMap = (e, fixedPlatform) => {
        e.preventDefault();
        openMapByDevice(fixedPlatform, latitude, longitude);
    }

	return (
		<ButtonStyled {...parentProps}>
            { platform !== 'ios' &&
                <button onClick={(e) => openMap(e, 'android')}>
                    {props.children}
                </button>
            }

            { platform === 'ios' &&
                <div className="dropdown">
                    <button {...parentProps} type="button" data-bs-toggle="dropdown">
                        {props.children}
                    </button>
                    <ul className="dropdown-menu">
                        <li><a href="/" className="btn btn-link dropdown-item" onClick={(e) => openMap(e, 'ios')}>Apple Maps</a></li>
                        <li><a href="/" className="dropdown-item" onClick={(e) => openMap(e, 'android')}>Google Maps</a></li>
                    </ul>
                </div>
            }
		</ButtonStyled>
	);
}


