import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';

export function assets(asset) {
	return process.env.PUBLIC_URL + asset;
}

export const jsonToArray = (json) => {
	let array = [];
	for(let i in json) array.push({key: i, value: json[i]});
	return array;
}

export const formatNumber = (number, decimals = 0, force_decimals = true, toLocaleString = false) => {
	if ( isNaN(number) ) number = 0;
	else number = parseFloat(number);

	const split = number.toString().split('.');
	if ( split[1] ) {
		let integer = parseInt(split[1]);
		if ( integer > 0 ) {
			if ( decimals ) {
				if ( force_decimals ) number = number.toFixed(decimals);
				else {
					let decimalsStr = split[1];
					decimalsStr.replaceAll('0', '');
					number = number.toFixed(decimalsStr.length > decimals ? decimals : decimalsStr.length);
				}
			}
		}
		else number = parseInt(number);
	} else {
		number = parseInt(number);
	}
	if ( toLocaleString ) number = parseFloat(number).toLocaleString();
	return number;
}
export const readableSize = (x) => {
	const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let l = 0, n = parseInt(x, 10) || 0;
	while(n >= 1024 && ++l){
		n = n/1024;
	}
	return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export const ucfirst = (str) => {
    return str[0].toUpperCase() + str.slice(1);
}

export const openBlob = async (blob, fileName) => {
	const readFileAsync = (file) => {
		return new Promise((resolve, reject) => {
			let reader = new FileReader();
			reader.onload = () => {
				resolve(reader.result);
			}
			reader.onerror = reject;
			reader.readAsDataURL(file); 
		});
	}

	let outputData = await readFileAsync(blob);
	outputData = outputData.substring(outputData.indexOf('base64,')+7);

	const createFileResult = await Filesystem.writeFile({
		path: fileName ?? 'file',
		data: '',
		directory: Directory.Cache
	});

	// Save appending because large files crashes app
	let readLength = 1000000;
	let i = 0;
	while(i<outputData.length) {
		let substring = outputData.substr(i, readLength);
		i += readLength;

		await Filesystem.appendFile({
			path: fileName ?? 'file',
			data: substring,
			directory: Directory.Cache
		});
	}
	
	// Get file saved path
	let fileSavedPath = createFileResult.uri;
	
	// Open file
	try {
		await FileOpener.open({
			filePath: fileSavedPath
		});
	} catch (e) {
		console.error('Error opening file.', 'Code: ' + e.code, 'Message: ' + e.message);
	}

	return true;
}

export const openMapByDevice = (device, latitude, longitude) => {
	if ( device === 'ios' ) window.open("https://maps.apple.com/?q="+latitude+','+longitude+"&t=k", '_system');
	if ( device === 'android' || device === 'web' ) window.open("https://maps.google.com/?q="+latitude+','+longitude, '_system');
	return false;
}