import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import useStore from "./store";

const MapCenterer = (props) => {	
	const center = useStore(state => state.center);
	const provinceId = useStore(state => state.provinceId);

	let map = useMap();

	useEffect(() => {
		if ( center.latitude && center.longitude ) {
			let ll = {lat: center.latitude, lng: center.longitude};
			if ( provinceId ) map.flyTo(ll, 9);
			else map.flyTo(ll);
		}
	}, [center.latitude, center.longitude, provinceId, props.refs, map]);

	return null;
}

export default MapCenterer;