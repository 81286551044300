import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import useStore from "./store";
import { assets, formatNumber } from 'helpers/generic';
import { __ } from 'helpers/i18n';
import OpenMapButton from 'components/OpenMapButton';

const PopupStyled = styled.div` 
	--width: 380px;

	all: revert;
	font-family: 'Red Hat Display';
	position: absolute;
	bottom: 0px;
	left: -180px;
	width: var(--width);
	margin-left: calc(var(--width) / 10); // fix needed to center popup
	height: 250px;
	background-color: transparent;
	z-index: 10001;
	user-select: none;
	background: white;
	border-radius: 30px;
	overflow: hidden;
	display: block;

	* {
		all: revert;
	}

	.inner {
		display: flex;
		flex-direction: column;
		height: 100%;

		.top {
			background: url(${assets('/assets/img/mapa-fondo-area-clientes.jpg')}) 40%;
			background-size: cover;
			height: 50%;
			display: flex;
			justify-content: space-between;
			padding: 20px;

			div {
				background: var(--plenoil-blue);
				display: flex;
				align-items: center;
				padding: 8px 18px;
				padding-right: 14px;
				border-radius: 25px;
				align-self: start; 
				color: white;
				cursor: pointer;

				button {
					display: flex;
					align-items: center;
				}

				img {
					width: 15px;
					margin-left: 10px;
				}

				&:hover {
					/* opacity: 0.9; */
				}

				&:last-of-type {
					margin-left: auto;
				}
			}
		}

		.bottom {
			height: 50%;
			display: flex;
			align-items: center;
			justify-content: end;
			flex-direction: column;

			.name {
				font-size: 18px;
				font-weight: 500;
			}

			.street {
				margin-bottom: 20px;
				font-size: 14px;
			}
		}
	}

	.prices {
		position: absolute;
		top: 50%;
		height: 70px;
		width: 100%;
		margin-top: -20px;
		display: flex;
		align-items: center;
		justify-content: center;

		.oil {
			width: 100px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			color: white;
			font-weight: 500;
			border-radius: 20px;
			margin-left: 2.5px;
			margin-right: 2.5px;

			.oil_title {
				font-size: 11px;
				text-transform: uppercase;
				margin-bottom: 5px;
			}

			.oil_price {
				font-size: 16px;
			}

			&.diesel {
				background: var(--plenoil-black);
			}

			&.gasolina {
				background: var(--plenoil-green);
			}

			&.adblue {
				background: var(--plenoil-blue2);
			}

			&.electriccharger {
				background: var(--plenoil-orange);

				.name {
					margin-top: 0;
				}
			}
		}
	}
`;

// Prepare custom icon
const icon = new L.Icon({
    iconUrl: assets('/assets/img/mapa-estacion.svg'),
    iconRetinaUrl: assets('/assets/img/mapa-estacion.svg'),
    popupAnchor:  [-0, -0],
    iconSize: [20,20],     
});

const CustomMarker = (props) => {
	const station = props.station;

	const dispatch = useStore(state => state.dispatch);

	const map = useMap();

	let navigate = useNavigate();

	const [popupReady, setPopupReady] = useState(false);
	const popupRef = useRef(null);

	useEffect(() => {
		if ( !popupReady ) return;

		let listStationClickListener = window.addEventListener('list-station-click-listener', (e) => {
			if ( e.detail === station.id ) popupRef.current.openOn(map);
		});

		return function cleanup() {
			window.removeEventListener('list-station-click-listener', listStationClickListener);
		}
	}, [popupReady, station.id, map]);

	const onClickEvent = (e) => {
		dispatch({
			type: 'setActiveMarker',
			station_id: undefined,
			center: {
				latitude: e.latlng.lat,
				longitude: e.latlng.lng
			}
		});
	}

	const openStationUrl = (id) => {
		navigate('/station/' + id)
	}

	let style = {};
	if ( station.photo_url ) {
		style['background'] = 'url('+station.photo_url+') 40%';
		style['backgroundSize'] = 'cover';
	}
		
	return station.latitude === null || station.longitude === null ? null : (
	    <Marker 
	    	position={[station.latitude, station.longitude]} 
	    	icon={icon} 
	    	key={station.id} 
	    	eventHandlers={{
			    click: (e) => onClickEvent(e)
  			}}
	    >
			<Popup ref={(r) => {
				popupRef.current = r;
				setPopupReady(true);
			}}>
				<PopupStyled className="popup-map">
					<div className="inner">
						<div className="top" style={style}>
							<div className="view" onClick={() => openStationUrl(station.id)}>{__('map.customMarker.stationButton')} <img src={assets('/assets/img/mapa-icono-vista-previa-b.svg')} alt="preview" /></div>

							<OpenMapButton className="btn" latitude={station?.latitude} longitude={station?.longitude}>
								{__('map.customMarker.mapButton')} <img src={assets('/assets/img/mapa-ubicacion-b.svg')} alt="map" />
							</OpenMapButton>
						</div>
						<div className="bottom">
							<div className="name">{station.name}</div>
							<div className="street">{station.street}</div>
						</div>
					</div>
					
					<div className="prices">
						<div className="oil diesel">
							<div className="oil_title">{__('map.customMarker.diesel')}</div>
							<div className="oil_price">{formatNumber(station.oilDiesel, 3, true, true)}€</div>
						</div>
						<div className="oil gasolina">
							<div className="oil_title">{__('map.customMarker.sp95')}</div>
							<div className="oil_price">{formatNumber(station.oil95, 3, true, true)}€</div>
						</div>
						{ station.adblue > 0 &&
							<div className="oil adblue">
								<div className="name">{__('station.adblue')}</div>
								<div className="price">{station.adblue ? formatNumber(station.adblue, 3, true, true) : '-,---'} €</div>
							</div>
						}
						{ station.electriccharger === 1 &&
							<div className="oil electriccharger">
								<div className="name">{__('station.electriccharger')}</div>
							</div>
						}
					</div>
				</PopupStyled>
			</Popup>
		</Marker>
  	);
}

export default CustomMarker;