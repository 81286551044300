import React, { useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import NotLoggedLayout from 'layouts/NotLoggedLayout';
import { getUser, setUser } from 'helpers/user';
import { assets } from 'helpers/generic';
import Auth from 'services/Auth';
import { toast } from "react-toastify";
import CristalLoader from "components/CristalLoader";
import { __, setLanguage } from "helpers/i18n";

const LoginStyled = styled.div` 
	#btn-home {
		font-size: 20px;
		margin-left: 10px;
		width: 30px;

		position: absolute;
		top: 15px;
		left: 10px;

		img {
			width: 20px;
		}
	}

	#logo {
		width: 120px;
		margin: 0 auto;
		margin-top: 15px;
		display: block;
	}

	#user {
		display: block;
		width: 100px;
		margin: 0 auto;
		margin-top: 100px;
	}

	#login-form {
		background: white;
		max-width: 400px;
		margin: 0 auto;
		padding-top: 80px;
		padding-bottom: 80px;

		#logo {
			display: block;
			margin: 0 auto;
			max-width: 150px;
			margin-bottom: 60px;
		}

		.input-wrapper {
			margin: 0 auto;
			margin-bottom: 10px;
		}

		button {
			width: 100%;
			margin-top: 20px;
			font-size: 16px;
			padding: 5px 10px;
		}

		p {
			font-size: 14px;
			margin-bottom: 25px;
		}

		.recover-link-wrapper {				
			margin-top: 20px;
			text-align: center;

			button {
				font-size: 15px;
				font-weight: 500;
				color: var(--plenoil-black);
				text-decoration: underline;
				margin-top: 0px;

				.small {
					margin-right: 5px;
				}
			}
		}
	}

	#new-account {
		color: var(--plenoil-blue2) !important;
		font-size: 18px;
		text-decoration: underline;
		padding-top: 0 !important;
	}
	
	.privacy-link {
		color: var(--bs-gray-800);
		text-decoration: unset !important;
		font-size: 12px;
	}
`;

export default function Login() {
	// Refs
	const usernameRef = useRef(null);
	const passwordRef = useRef(null);
	const usernameRecoverRef = useRef(null);

	// State
	let [recoverPass, setRecoverPass] = useState(false);
	let [loading, setLoading] = useState(false);

	const login = async (e) => {
		e.preventDefault();

		setLoading(true);

		let result = await Auth.login(usernameRef.current.value, passwordRef.current.value);
		if (!result) {
			toast.error(__('login.invalidlogin'));
		} else {
			setUser({...result.user, accessToken: result.access_token}, true, true);
			setLanguage(result.user.language);
		}

		setLoading(false);
	};

	const recover = async (e) => {
		e.preventDefault();

		setLoading(true);

		let result = await Auth.recoverPassword(usernameRecoverRef.current.value);
		if (result === -1) {
			toast.warning(__('login.recoverpasswordthrottle'));
		}
		if (result === false) {
			toast.error(__('login.invalidlogin'));
		}
		if (result === true) {
			toast.success(__('login.recoverpasswordemailsent'));
			setRecoverPass(false);
			usernameRecoverRef.current.value = '';
		}
		
		setLoading(false);
	};

	if ( getUser() ) return <Navigate to="/" />;

	// Render
	return (
		<NotLoggedLayout>
			{loading && <CristalLoader />}
			<LoginStyled>
				<NavLink to="/" id="btn-home"><img src={assets('/assets/img/inicio.svg')} alt="home" /></NavLink>
				<img id="logo" src={assets('/assets/img/Plenoil-logo-2022.svg')} alt="logo" />
				<img id="user" src={assets('/assets/img/cliente-inicio-redondo.svg')} alt="user" />

				<div id="login-form">
					{!recoverPass ?
						<React.Fragment>
							<label className="label-plenoil">{__('login.loginForm.input.user')}</label>
							<div className="input-wrapper">
								<input ref={usernameRef} type="text" className="input-plenoil" />
							</div>

							<label className="label-plenoil">{__('login.loginForm.input.password')}</label>
							<div className="input-wrapper mb-0">
								<input ref={passwordRef} type="password" className="input-plenoil" />
							</div>	
						
							<button onClick={(e) => login(e)} className="btn btn-plenoil btn-plenoil-blue2 w-100">{__('login.loginForm.loginbutton')}</button>

							<div className="recover-link-wrapper">
								<button className="btn btn-link" onClick={() => setRecoverPass(true)}>{__('login.loginForm.lostpasswordButton')}</button>
							</div>
						</React.Fragment>
						:
						<React.Fragment>
							<div className="text-center">
								<p>
									{__('login.recoverPasswordForm.text1')}<br />
									{__('login.recoverPasswordForm.text2')}
								</p>
							</div>

							<div className="input-wrapper">
								<input ref={usernameRecoverRef} placeholder={__('login.recoverPasswordForm.usernameInput')} type="text" className="input-plenoil" />
							</div>

							<div className="text-center">
								<button onClick={(e) => recover(e)} className="btn btn-plenoil btn-plenoil-blue2">{__('login.recoverPasswordForm.button')}</button>
							</div>

							<div className="recover-link-wrapper">
								<button className="btn btn-link" onClick={() => setRecoverPass(false)}><i className="bi bi-chevron-left small"></i> {__('login.recoverPasswordForm.gobackbutton')}</button>
							</div>
						</React.Fragment>
					}
				</div>

				<div>
					<div className="text-center small">
						{__('login.noaccountask')}
					</div>
					<div className="text-center">
						<NavLink className="btn btn-plenoil btn-plenoil-white" to="/register" id="new-account">{__('login.createaccountbutton')}</NavLink>
					</div>
					<div className="text-center mt-3">
						<a href="https://plenoil.es/politica-de-privacidad-plenoil/" className="privacy-link" target="_blank" rel="noreferrer">{__('login.privacylink')}</a>
					</div>
				</div>
			</LoginStyled>
		</NotLoggedLayout>
	);
}