import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import CristalLoader from 'components/CristalLoader';
import { default as MessagesService } from 'services/Message';
import { __ } from 'helpers/i18n';

const ContainerStyled = styled.div` 
	margin-top: 30px 0;
`;

const MessagesWrapper = styled.div` 
	padding: 10px 20px;

	.message {
		text-align: center;
		padding: 5px 10px;

		&:not(:last-of-type) {
			border-bottom: 1px solid var(--bs-gray-400);
		}
	}
`;

export default function Account() {
	let [data, setData] = useState({
		current_page: null,
		next_page: null,
		last_page: null,
		messages: []
	});
	let [loading, setLoading] = useState(false);

	const loadMesages = useCallback(async (page) => {
		setLoading(true);

		let newData = await MessagesService.list(page);
		if ( newData ) {
			setData((data) => ({
				current_page: newData.current_page,
				next_page: newData.next_page,
				last_page: newData.last_page,
				messages: [...data.messages, ...newData.data]
			}));
		}

		setLoading(false);
	}, []);

	useEffect(() => {
		loadMesages(1);

		return function cleanup() {
			MessagesService.cancel();
		}
	}, [loadMesages]);
	
	return (
		<LoggedLayout>
			{loading && <CristalLoader />}
			<ContainerStyled>
				<div className="page-title">{__('messages.title')}</div>

				<MessagesWrapper>
					{ data.messages.length > 0 &&
						data.messages.map((el, idx) => {
							return (
								<div className="message" key={idx}>
									{el.message}
								</div>
							);
						})
					}

					{ !data.messages.length &&
						<div className="message">{__('messages.empty')}</div>
					}

					{ data.current_page < data.last_page &&
						<button className="btn-plenoil btn-plenoil-blue2 w-100 mt-3" onClick={() => loadMesages(data.next_page)}>{__('messages.loadMoreButton')}</button>
					}
				</MessagesWrapper>	
			</ContainerStyled>
		</LoggedLayout>
	);
}


