import create from 'zustand';

// Create reducer
const reducer = (state, action) => {
	if ( action.type === "setStations" ) {
		return {stations: action.data};
	}
	if ( action.type === "setProvinces" ) {
		return {provinces: action.data};
	}
	if ( action.type === "setProvinceId" ) {
		return {provinceId: action.data};
	}
	if ( action.type === "setWithWash" ) {
		return {withWash: action.data};
	}
	if ( action.type === "setWithElectriccharger" ) {
		return {withElectriccharger: action.data};
	}

	if ( action.type === "setPosition" ) {
		return {position: action.data};
	}

	if ( action.type === "setCenter" ) {
		return {center: action.data};
	}

	if ( action.type === "setActiveMarker" ) {
		let toUpdate = {};
		toUpdate.activeMarker = action.station_id;
		if ( action.center ) toUpdate.center = action.center; 
		return toUpdate;
	}

	// Not valid type
	throw new Error();
}

// Default state
const defaultState = {
	center: {
		latitude: null,
		longitude: null
	},
	position: {
		latitude: null,
		longitude: null
	},
	stations: [],
	provinces: [],
	provinceId: null,
	activeMarker: null,
	withWash: false,
	withElectriccharger: false
};

// Create hook
const useStore = create(set => ({
	...defaultState,
	dispatch: (action) => set(state => reducer(state, action)),
}));

export default useStore;