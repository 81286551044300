import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import axios from 'axios';
import { getUser, setUser } from 'helpers/user';
import { __ } from "helpers/i18n";

export default function Logout() {

    useEffect(() => {
    	const logout = () => {
	    	axios
				.post('api/logout', {})
				.then((response) => {
					
				})
				.catch((error) => {

				})
				.then(() => {
					setUser(null, true);
					window.location.reload();
				})
	    }
	    logout();

    }, []);

	return (
		<React.Fragment>
			{ 
				getUser() ?
					<div className="text-center mt-5"> 
						{__('logout.text')}
					</div>
				:
				<Navigate to="/" />
			}
		
		</React.Fragment>
	)
}