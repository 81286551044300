import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import CristalLoader from 'components/CristalLoader';
import { default as UsersService } from 'services/User';
import { toast } from 'react-toastify';
import { jsonToArray } from 'helpers/generic';
import { __, getLanguages, setLanguage, getLanguage } from 'helpers/i18n';

const ContainerStyled = styled.div` 
	margin-top: 30px 0;

	h2 {
		font-size: 15px;
	}

	input.input-plenoil,
	select.input-plenoil {
		padding: 10px 20px;
		font-size: 15px;
	}

	.btn-plenoil {
		padding: 10px 50px;
		font-size: 15px;
	}

	.label-plenoil {
		text-transform: none !important;
	}
`;

const ConcentradorProcessCristal = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.8);
	border-radius: 30px;
	padding: 20px;
	z-index: 5;

	span {
		background: white;
		color: var(--plenoil-blue2);
		border-radius: 30px;
		text-align: center;
		display: block;
		padding: 10px 20px;
		box-shadow: var(--plenoil-shadow);
	}
	&:after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		font-size: 20px;
		text-align: center;
		background: white;
	}
`;

const FieldsWrapper = styled.div` 
	padding: 20px;
	position: relative;
`;

const Buttons = styled.div` 
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 30px;

	button {
		width: 100%;
	}

	a {
		width: 100%;
		text-align: center;
		color: var(--plenoil-black);
		text-decoration: none;
		font-size: 12px !important;
		font-weight: bold;
	}
`;

let checkConcentradorProcessInterval = null;

export default function UserEdit() {
	let [forceReload, setForceReload] = useState(null);
	let [loading, setLoading] = useState(false);
	let [provinces, setProvinces] = useState([]);
	let [data, setData] = useState({});
	let [errors, setErrors] = useState({});

	useEffect(() => {
		const getData = async () => {
			setLoading(true);

			let provinces = await UsersService.getProvinces();
			setProvinces(provinces);

			let data = await UsersService.getData();
			let province_exists = provinces?.findIndex(el => el.name.replaceAll(' ', '').toLowerCase() === data?.province.replaceAll(' ', '').toLowerCase()) !== -1;
			if  (!province_exists) data.province = '';
			setData(data);

			setLoading(false);
		}
		getData();
	}, [forceReload]);

	useEffect(() => {
		if ( data?.concentrador_process ) {
			clearInterval(checkConcentradorProcessInterval);
			checkConcentradorProcessInterval = setInterval(() => {
				setForceReload(Math.random());
			}, 5000);
		}

		return function cleanup() {
			clearInterval(checkConcentradorProcessInterval);
		}
	}, [data]);
	
	const setDataField = (field, value) => {
		setData((prev) => ({...prev, [field]: value}));
	}

	const saveData = async () => {
		if ( !data ) return;
		
		setErrors({});
		setLoading(true);
		let result = await UsersService.updateData({
			name: data.name,
			email: data.email,
			postalcode: data.postalcode,
			province: data.province,
			city: data.city,
			address: data.address,
			phone: data.phone,
			password: data.password
		});
		if (result.status) {
			toast.success(__('account.userEdit.successMessage'));
			setForceReload(Math.random()); // Force reload data to show concentrador cristal
		} else {
			setErrors(result.errors)
			toast.error(__('account.userEdit.errorMessage'));
		}
		setLoading(false);
	}

	let languages = getLanguages();
	languages = jsonToArray(languages);

	const setLang = (lang) => {
		if ( !lang ) return;
		setLanguage(lang);
	}

	return (
		<LoggedLayout>
			{loading && <CristalLoader />}
			<ContainerStyled>
				<div className="page-title">{__('account.userEdit.title')}</div>

				<FieldsWrapper>
					{ data?.concentrador_process && <ConcentradorProcessCristal><span>{__('account.userEdit.concentrador.onprogressmessage')}</span></ConcentradorProcessCristal> }

					<div className="mb-2">
						<label className="label-plenoil">{__('account.userEdit.input.name.label')}</label>
						<input type="text" className="input-plenoil" placeholder={__('account.userEdit.input.name.placeholder')} value={data?.name ?? ''} onChange={(e) => setDataField('name', e.target.value)} />
						{ errors['name'] && <div className="invalid-feedback d-block">{errors['name'][0]}</div> }
					</div>

					<div className="mb-2">
						<label className="label-plenoil">{__('account.userEdit.input.phone.label')}</label>
						<input type="text" className="input-plenoil" placeholder={__('account.userEdit.input.phone.placeholder')} value={data?.phone ?? ''} onChange={(e) => setDataField('phone', e.target.value)} />
						{ errors['phone'] && <div className="invalid-feedback d-block">{errors['phone'][0]}</div> }
					</div>

					<div className="mb-2">
						<label className="label-plenoil">{__('account.userEdit.input.email.label')}</label>
						<input type="email" className="input-plenoil" placeholder={__('account.userEdit.input.email.label')} value={data?.email ?? ''} onChange={(e) => setDataField('email', e.target.value)} />
						{ errors['email'] && <div className="invalid-feedback d-block">{errors['email'][0]}</div> }
					</div>

					<div className="mb-2">
						<label className="label-plenoil">{__('account.userEdit.input.address.label')}</label>
						<input type="text" className="input-plenoil" placeholder={__('account.userEdit.input.address.placeholder')} value={data?.address ?? ''} onChange={(e) => setDataField('address', e.target.value)} />
						{ errors['address'] && <div className="invalid-feedback d-block">{errors['address'][0]}</div> }
					</div>

					<div className="mb-2">
						<label className="label-plenoil">{__('account.userEdit.input.province.label')}</label>
						<select 
							className="input-plenoil" 
							placeholder={__('account.userEdit.input.province.placeholder')}
							value={data?.province ?? ''} 
							onChange={(e) => setDataField('province', e.target.value)}
						>
							<option value="">{__('account.userEdit.input.province.placeholder')}</option>
							{provinces?.map((el, idx) => {
								return (
									<option value={el.name.toUpperCase()} key={idx}>{el.name}</option>
								)
							})}
						</select>
						{ errors['province'] && <div className="invalid-feedback d-block">{errors['province'][0]}</div> }
					</div>

					<div className="mb-2">
						<label className="label-plenoil">{__('account.userEdit.input.city.label')}</label>
						<input type="text" className="input-plenoil" placeholder={__('account.userEdit.input.city.placeholder')} value={data?.city ?? ''} onChange={(e) => setDataField('city', e.target.value)} />
						{ errors['city'] && <div className="invalid-feedback d-block">{errors['city'][0]}</div> }
					</div>

					<div className="mb-2">
						<label className="label-plenoil">{__('account.userEdit.input.postalcode.label')}</label>
						<input type="text" className="input-plenoil" placeholder={__('account.userEdit.input.postalcode.placeholder')} value={data?.postalcode ?? ''} onChange={(e) => setDataField('postalcode', e.target.value)} />
						{ errors['postalcode'] && <div className="invalid-feedback d-block">{errors['postalcode'][0]}</div> }
					</div>

					<div className="mb-2">
						<label className="label-plenoil">{__('account.userEdit.input.password.label')}</label>
						<input type="text" className="input-plenoil" placeholder={__('account.userEdit.input.password.placeholder')} value={data?.password ?? ''} onChange={(e) => setDataField('password', e.target.value)} />
						{ errors['password'] && <div className="invalid-feedback d-block">{errors['password'][0]}</div> }
					</div>

					<div className="mb-2">
						<label className="label-plenoil">{__('splash.langselector.label')}</label>
						<select onChange={(e) => setLang(e.target.value)} value={getLanguage() ?? ''} className="input-plenoil">
							{languages.map(el => 
								<option key={el.key} value={el.key}>{el.value}</option>
							)}
						</select>
						{ errors['language'] && <div className="invalid-feedback d-block">{errors['language'][0]}</div> }
					</div>
					
					<Buttons>
						<button className="btn-plenoil btn-plenoil-blue2" onClick={saveData}>{__('account.userEdit.saveButton')}</button>
						<NavLink to="/account" className="btn-plenoil mt-2">{__('account.userEdit.cancelButton')}</NavLink>
					</Buttons>
				</FieldsWrapper>
			</ContainerStyled>
		</LoggedLayout>
	);
}


