import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useSearchParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import 'leaflet/dist/leaflet.css';
import { Geolocation } from '@capacitor/geolocation';
import LoggedLayout from 'layouts/LoggedLayout';
import MapCenterer from './MapCenterer';
import CustomMarker from './CustomMarker';
import CurrenPositionMarker from './CurrenPositionMarker';
import Buttons from './Buttons';
// import TopFilters from './TopFilters';
import BottomButtons from './BottomButtons';
import useStore from "./store";
import { default as StationService } from 'services/Station';
import { __ } from 'helpers/i18n';

const GlobalStyle = createGlobalStyle` 
	html, body {
		&.ios {
			#mapContainer {
				margin-bottom: 30px;
			}
		}
	}
`;

const ContainerStyled = styled.div` 	
	margin-top: 30px 0;
	display: flex;
	flex-direction: column;
	height: 100%;

	.page-title {
		width: 300px;
		border-bottom: 0;
		margin-bottom: 0;
	}
`;

const StyledMap = styled.div` 
	display: block;
	width: 100%;
	height: 100%;
	position: relative;

	#mapContainer {
		display: block;
		width: 100%;
	}

	&.mobile {
		.listContainer {
			position: static;
			max-width: 350px;
			max-height: 400px;
			margin: 10px auto;
		}
	}

	.leaflet-container {
	    height: 100%;

	    .leaflet-popup-tip {
	    	background: white; 
	    }
	}
`;


const OpenStreetMap = () => {
	let [searchParams] = useSearchParams();
	let withWashSP = searchParams.get('wash') === 'true';

	let [loaded, setLoaded] = useState(false);

	const dispatch = useStore(state => state.dispatch);
	const position = useStore(state => state.position);
	const stations = useStore(state => state.stations);
	const provinceId = useStore(state => state.provinceId);
	const withWash = useStore(state => state.withWash);
	const withElectriccharger = useStore(state => state.withElectriccharger);

	// First init, always set province to null
	useEffect(() => {
		dispatch({
			type: 'setProvinceId',
			data: null
		});
	}, [dispatch]);

	// Fix to set wash from query string
	useEffect(() => {
		if ( !loaded || !withWashSP ) return;

		dispatch({
			type: 'setWithWash',
			data: true
		});
	}, [loaded, withWashSP, dispatch]);

	// Get position
	useEffect(() => {
		let getPositionInterval = setInterval(async () => {
			let coordinates = null;
			try {
				coordinates = await Geolocation.getCurrentPosition();
			} catch (e) {}

			dispatch({
				type: 'setPosition',
				data: {
					latitude: coordinates?.coords?.latitude ?? null,
					longitude: coordinates?.coords?.longitude ?? null
				}
			});
		}, [5000]);

		return function cleanup() {
			clearInterval(getPositionInterval);
		}
	}, [dispatch]);

	// Load stations
	useEffect(() => {
		let getProvinces = async () => {
			const provinces = await StationService.listProvinces();
			if ( provinces ) {
				dispatch({
					type: 'setProvinces',
					data: provinces
				});
			}
		}
		getProvinces();
		
		let getStations = async () => {
			let stations = await StationService.listByProvinces(position.latitude ?? null, position.longitude ?? null, provinceId, withWash, withElectriccharger);
			setLoaded(true);
			if ( stations ) {
				dispatch({
					type: 'setStations',
					data: stations
				});

				if ( stations.length === 1 && stations[0]?.stations[0] ) {
					let station = stations[0]?.stations[0];
					dispatch({
						type: 'setActiveMarker',
						station_id: station.id,
						center: {
							latitude: station.latitude,
							longitude: station.longitude
						}
					});
				}
			}
		}
		getStations();

		return function cleanup() {
			StationService.cancel();
		}
	}, [dispatch, position, provinceId, withWash, withElectriccharger]);

	// Prepare markers`
	let markers = [];
	if ( stations.length > 0 ) {
		stations.forEach((province, provinceIdx) => {
			province.stations.forEach((station) => {
				markers.push(<CustomMarker key={station.id} station={station} />);
			});
		});
	}
	markers.push(<CurrenPositionMarker key='current' />);
		
	return (
		<LoggedLayout>
			<GlobalStyle />
			<ContainerStyled>
				<div className="page-title">{__('map.title')}</div>
				<StyledMap id="mapContainer">
					<MapContainer 
						center={[40.4363019, -3.8182474]} 
						zoom={6} 
						minZoom={4}
						attributionControl={false}
						zoomControl={false}
					>
						<MapCenterer />
						<TileLayer 
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						/>
						{markers}
						<Buttons />
						{/* <TopFilters /> */}
						<BottomButtons />
					</MapContainer>
				</StyledMap>
			</ContainerStyled>
		</LoggedLayout>
	);
}

export default OpenStreetMap;