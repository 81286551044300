const lang = {
     'langOrder': 2,
     'langCode': 'ca',
     'langName': 'Català',

     /**
     * layouts/HamburguerMenu.js
     */
     'hamburguerMenu.links.stations': 'Benzineres',
     'hamburguerMenu.links.consumptions': 'Consums',
     'hamburguerMenu.links.refuelings': 'Repostatges',
     'hamburguerMenu.links.invoices': 'Factures',
     'hamburguerMenu.links.messages': 'Missatges',
     'hamburguerMenu.links.account': 'Les meves dades',
     'hamburguerMenu.logoutButton': 'Tancar sessió',
     'hamburguerMenu.deleteAccountButton': 'Eliminar compte',
     'hamburguerMenu.deleteAccountPopup.title': 'Introduïu la contrasenya per eliminar el vostre compte',
     'hamburguerMenu.deleteAccountPopup.deleteButton': 'ELIMINAR',
     'hamburguerMenu.deleteAccountPopup.privacyLink': "Termes d'ús i Política de privadesa",
     'hamburguerMenu.deleteAccountPopup.confirmMessage': 'Esteu segur que voleu continuar?',

     /**
     * layouts/LoggedLayout.js
     */
     'loggedlayout.bottomMenu.stations': 'Benzineres',
     'loggedlayout.bottomMenu.consumptions': 'Consums',
     'loggedlayout.bottomMenu.refuelings': 'Repostatges',
     'loggedlayout.bottomMenu.invoices': 'Factures',
     'loggedlayout.bottomMenu.messages': 'Missatges',
     'loggedlayout.bottomMenu.account': 'Les meves dades',

     /**
     * pages/Splash.js
     */
     'splash.langselector.label': 'Seleccionar idioma',
     'splash.animation.text1': 'Energia al millor',
     'splash.animation.text2': 'preu per al vostre vehicle',
     'splash.mapbutton': 'VEURE BENZINERES I PREUS',
     'splash.loginbutton': 'INICIAR SESSIÓ',
     'splash.ask': 'Encara no tens un compte a Plenoil?',
     'splash.createaccountbutton': 'Crear compte',
     'splash.policylink': "Termes d'ús i Política de privadesa",

     /**
     * pages/Login.js
     */
     'login.recoverpasswordthrottle': 'Has intentat sol·licitar una nova contrasenya massa ràpid. Espera 1 minut per tornar-ho a provar.',
     'login.invalidlogin': 'Dades d\'accés invàlides',
     'login.recoverpasswordemailsent': "T'hem enviat un email amb les instruccions per recuperar la teva contrasenya",
     'login.loginForm.input.user': 'NIF/CIF (sense espais)',
     'login.loginForm.input.password': 'CONTRASENYA',
     'login.loginForm.loginbutton': 'INICIAR SESSIÓ',
     'login.loginForm.lostpasswordButton': 'He oblidat la contrasenya',
     'login.recoverPasswordForm.text1' : 'Introdueix el teu NIF/CIF per rebre un email amb les',
     'login.recoverPasswordForm.text2' : 'instruccions per recuperar la contrasenya',
     'login.recoverPasswordForm.usernameInput': 'NIF/CIF',
     'login.recoverPasswordForm.button': 'SOL·LICITAR NOVA CONTRASENYA',
     'login.recoverPasswordForm.gobackbutton': 'Tornar enrere',
     'login.noaccountask': 'Encara no tens un compte a Plenoil?',
     'login.createaccountbutton': 'Crear compte',
     'login.privacylink': "Termes d'ús i Política de privadesa",

     /**
     * pages/Logout.js
     */
     'logout.text': 'Tancant sessió...',

          /**
     * pages/Register.js
     */
     'register.registerErrorMessage': 'Si us plau, corregiu els errors abans de continuar',
     'register.genericErrorMessage': 'Ha passat un error, si us plau torneu-ho a provar',
     'register.confirmationMessageResent' : 'Missatge de confirmació torna a enviar',
     'register.title.userData': 'Dades d\'usuari',
     'register.inputs.name.label': 'Nom i cognoms / Nom empresa',
     'register.inputs.name.placeholder': 'Nom Empresa SL',
     'register.inputs.vatnumber.label': 'CIF/NIF',
     'register.inputs.vatnumber.placeholder': 'XXXXXXXXX',
     'register.inputs.email.label': 'Email',
     'register.inputs.email.placeholder': 'email@exemple.com',
     'register.inputs.repeatemail.label': 'Repetir email',
     'register.inputs.repeatemail.placeholder': 'email@exemple.com',
     'register.inputs.password.label': 'Contrasenya (mínim 6 digitos)',
     'register.inputs.password.placeholder': '******',
     'register.title.address': 'Direcció',
     'register.inputs.phone.label': 'Telèfon',
     'register.inputs.phone.placeholder': 'XXXXXXXXX',
     'register.inputs.province.label': 'Província',
     'register.inputs.province.placeholder': 'La teva província',
     'register.inputs.city.label': 'Localitat',
     'register.inputs.city.placeholder': 'La teva localitat',
     'register.inputs.address.label': 'Direcció',
     'register.inputs.address.placeholder': 'C/ Exemple, 10, 1A',
     'register.inputs.postalcode.label': 'Codi postal',
     'register.inputs.postalcode.placeholder': 'XXXXX',
     'register.title.invoices': 'FACTURES',
     'register.inputs.invoices.porsuministros.label': 'PER SUBMINISTRAMENT',
     'register.inputs.invoices.mensuales.label': 'MENSUALS',
     'register.inputs.invoices.porsuministros.text': 'rebreu una factura per a cada proveïment',
     'register.inputs.invoices.mensuales.text': 'rebreu una factura mensual amb tots els proveïments',
     'register.title.matricules': 'Matrícules',
     'register.inputs.matriculas.input.placeholder': 'Introduïu matrícula',
     'register.inputs.matriculas.addVehicleButton': 'Afegir vehicle',
     'register.policy.text1': 'He llegit i accepto la',
     'register.policy.text2': 'Política de privadesa',
     'register.createAccountButton': 'CREAR COMPTE',
     'register.cancelButton': 'Cancel·lar',
     'register.registrationCompleted.title': 'Compte creat',
     'register.registrationCompleted.text': 'Aviat rebràs un correu electrònic per verificar el teu compte',
     'register.registrationCompleted.verifiedButton': 'Ja he verificat',
     'register.registrationCompleted.resendEmailButton': 'Reenviar correu',

     /**
     * pages/Account/index.js
     */
     'account.index.title': 'LES MEVES DADES',
     'account.index.concentrador.onprogressmessage': 'Actualització en progrés. En breu estarà disponible. Podeu continuar navegant.',
     'account.index.inputs.name': 'Nom i cognoms / Nom empresa',
     'account.index.inputs.email': 'Email',
     'account.index.inputs.phone': 'Telèfon',
     'account.index.inputs.address': 'Direcció',
     'account.index.inputs.province': 'Província',
     'account.index.inputs.city': 'Localitat',
     'account.index.inputs.postalcode': 'Codi postal',
     'account.index.editButton': 'Editar dades',
     'account.index.editInvoicingButton': 'Editar facturació',
     'account.index.favoriteStation.placeholder': 'Benzinera preferida',

     /**
     * pages/Account/UserEdit.js
     */
     'account.userEdit.successMessage': 'Dades rebudes',
     'account.userEdit.errorMessage': 'Error en rebre les dades',
     'account.userEdit.title': "DADES D'USUARI",
     'account.userEdit.concentrador.onprogressmessage': 'Actualització en progrés. En breu estarà disponible. Podeu continuar navegant.',
     'account.userEdit.input.name.label': 'Nom i cognoms / Nom empresa',
     'account.userEdit.input.name.placeholder': 'Nom Empresa SL',
     'account.userEdit.input.phone.label': 'Telèfon',
     'account.userEdit.input.phone.placeholder': 'XXXXXXXXX',
     'account.userEdit.input.email.label': 'Email',
     'account.userEdit.input.email.placeholder': 'email@exemple.com',
     'account.userEdit.input.address.label': 'Direcció',
     'account.userEdit.input.address.placeholder': 'C/ Exemple, 10, 1A',
     'account.userEdit.input.province.label': 'Província',
     'account.userEdit.input.province.placeholder': 'La teva província',
     'account.userEdit.input.city.label': 'Ciutat',
     'account.userEdit.input.city.placeholder': 'La teva localitat',
     'account.userEdit.input.postalcode.label': 'Codi postal',
     'account.userEdit.input.postalcode.placeholder': 'XXXXXX',
     'account.userEdit.input.password.label': 'Contrasenya',
     'account.userEdit.input.password.placeholder': '******',
     'account.userEdit.saveButton': 'GUARDAR',
     'account.userEdit.cancelButton': 'CANCEL·LAR',

     /**
     * pages/Account/InvoicingEdit.js
     */
     'account.invoicingEdit.successMessage': 'Dades rebudes',
     'account.invoicingEdit.errorMessage': 'Error en rebre les dades',
     'account.invoicingEdit.title': 'FACTURACIÓ',
     'account.invoicingEdit.concentrador.onprogressmessage': 'Actualització en progrés. En breu estarà disponible. Podeu continuar navegant.',
     'account.invoicingEdit.porsuministro.label': 'PER SUBMINISTRAMENT',
     'account.invoicingEdit.porsuministro.text': 'rebreu una factura per a cada proveïment.',
     'account.invoicingEdit.mensuales.label': 'MENSUALS',
     'account.invoicingEdit.mensuales.text': 'rebreu una factura mensual amb tots els proveïments.',
     'account.invoicingEdit.vehicles.title': 'Vehicles',
     'account.invoicingEdit.vehicles.placeholder': 'Introduïu matrícula',
     'account.invoicingEdit.vehicles.addButton': 'Afegir vehicle',
     'account.invoicingEdit.saveButton': 'GUARDAR',
     'account.invoicingEdit.cancelButton': 'CANCEL·LAR',

     /**
     * pages/Consumptions/index.js
     */
     'consumptions.title': 'CONSUMS',
     'consumptions.mensualReport.thismonth': 'aquest mes',
     'consumptions.mensualReport.liters': 'LITROS',
     'consumptions.mensualReport.lastRefueling': 'Darrer proveïment',
     'consumptions.barsReportFilters.matriculas.all': 'TOTES',
     'consumptions.toggleButton.matricules': 'Veure per matrícula',
     'consumptions.toggleButton.resum': 'Veure resum',

     /**
     * pages/Consumptions/BarsReport.js
     */
     'consumptions.barsReport.empty': 'No hi ha consums',
     'consumptions.barsReport.totalYear': 'CONSUM TOTAL ANUAL',

     /**
     * pages/Consumptions/PieReport.js
     */
     'consumptions.pieReport.empty': 'No hi ha consums',

     /**
     * pages/Dashboard/index.js
     */
     'dashboard.link.consumptions': 'CONSUMS',
     'dashboard.link.refuelings': 'REPOSTATGES',
     'dashboard.link.invoices': 'FACTURES',
     'dashboard.link.account': 'LES MEVES DADES',

     /**
     * pages/Dashboard/Station.js
     */
     'dashboard.station.station': 'BENZINERA',
     'dashboard.station.favorite': 'FAVORITA',
     'dashboard.station.closest': 'MÉS PROPERA',
     'dashboard.station.mapButton': 'VEURE',
     'dashboard.station.diesel': 'DIESEL',
     'dashboard.station.sp95': 'SP95',

     /**
     * pages/Dashboard/Statistics.js
     */
     'dashboard.statistics.lastRefueling': 'ÚLTIM REPOSTATGE',
     'dashboard.statistics.monthSummary': 'RESUM DEL MES',

     /**
     * pages/Dashboard/UserMeta.js
     */
     'dashboard.userMeta.welcome': 'Benvingut',

     /**
     * pages/Invoices/index.js
     */
     'invoices.title': 'FACTURES',
     'invoices.table.date': 'DATA',
     'invoices.table.number': 'NOMBRE',
     'invoices.table.amount': 'IMPORT',
     'invoices.table.invoice': 'FACTURA',
     'invoices.table.empty': 'No hi ha factures',
     'invoices.selectAllButton': 'Seleccioneu-ho tot',
     'invoices.unselectButton': 'Deselecciona',
     'invoices.downloadButton': 'Descarregar factures',

     /**
     * pages/Messages/index.js
     */
     'messages.title': 'MENSATGES',
     'messages.empty': 'Encara no tens missatges',
     'messages.loadMoreButton': 'Carregar més',

     /**
     * pages/Refuelings/index.js
     */
     'refuelings.title': 'CONSUMS',
     'refuelings.table.date': 'DATA',
     'refuelings.table.station': 'ESTACIÓ',
     'refuelings.table.amount': 'IMPORT',
     'refuelings.table.ticket': 'TICKET',
     'refuelings.table.empty': 'No hi ha proveïments',

     /**
     * pages/Refuelings/TicketPreview.js
     */
     'refuelings.ticketpreview.downloadButton': 'Descarregar tiquet',

     /**
     * pages/Station/index.js
     */
     'station.title': 'LES NOSTRES BENZINERES',
     'station.electriccharger': 'RECÀRREGA',
     'station.adblue': 'ADBLUE',
     'station.diesel': 'DIESEL',
     'station.sp95': 'SP95',
     'station.mapButton': 'VEURE',
     'station.open24hours': 'Obert 24 hores',
     'station.personalized': 'Atenció personalitzada',

     /**
     * pages/StationsList/index.js
     */
     'stationslist.title': 'LES NOSTRES BENZINERES',
	'stationslist.ubication': 'Ubicació',


     /**
     * pages/Wash/index.js
     */
     'wash.mapButton': 'VEURE',
     'wash.backButton': 'enrere',
     'wash.minutes': 'minuts de rentat',
     'wash.lavallantas.text1': 'AIGUA DESCALCIFICADA FREDA + DETERGENT',
     'wash.lavallantas.text2': 'SITUEU LA LLANÇA A 15-20CM DEL VEHICLE',
     'wash.lavallantas.title': 'LAVA LLANTAS',
     'wash.prelavado.text1': 'AIGUA DESCALCIFICADA FREDA + ESCUMA NEÓ',
     'wash.prelavado.text2': 'SITUEU LA LLANÇA A 15-20CM DEL VEHICLE',
     'wash.prelavado.title': 'PRELAVAT',
     'wash.lavado.text1': 'AIGUA DESCALCIFICADA CALENT + DETERGENT',
     'wash.lavado.text2': 'SITUEU LA LLANÇA A 15-20CM DEL VEHICLE',
     'wash.lavado.title': 'RENTAT',
     'wash.aclarado.text1': 'AIGUA DESCALCIFICADA FREDA',
     'wash.aclarado.text2': 'SITUEU LA LLANÇA A 25-35CM DEL VEHICLE',
     'wash.aclarado.title': 'ACLARAT',
     'wash.superencerado.text1': 'AIGUA OZMOTITZADA FREDA + CERA',
     'wash.superencerado.text2': 'SITUEU LA LLANÇA A 25-35CM DEL VEHICLE',
     'wash.superencerado.title': 'SÚPER ENCERAT',
     'wash.superencerado2.text1': 'AIGUA OZMOTITZADA FREDA + CERA',
     'wash.superencerado2.text2': 'SITUEU LA LLANÇA A 50CM DEL VEHICLE',
     'wash.superencerado2.title': 'SÚPER ENCERAT',
     'wash.abrillantado.text1': 'AIGUA OZMOTITZADA FREDA',
     'wash.abrillantado.text2': 'SITUEU LA LLANÇA A 25-35CM DEL VEHICLE',
     'wash.abrillantado.title': 'ABRILLANTAT',
     'wash.abrillantado2.text1': 'AIGUA OZMOTITZADA FREDA',
     'wash.abrillantado2.text2': 'SITUEU LA LLANÇA A 50CM DEL VEHICLE',
     'wash.abrillantado2.title': 'ABRILLANTAT',

     /**
     * pages/Map/index.js
     */
     'map.title': 'LES NOSTRES BENZINERES',
     'map.viewlistbutton': 'VEURE LLISTAT DE BENZINERES',

     /**
     * pages/Map/CustomMarker.js
     */
     'map.customMarker.stationButton': 'Veure Estació',
     'map.customMarker.mapButton': 'Com arribar',
     'map.customMarker.diesel': 'dièsel',
     'map.customMarker.sp95': 'sense plom 95',

     /**
     * pages/Map/TopFilters.js
     */
     'map.topFilters.filterByProvince': 'Filtrar per província',
}

export default lang;