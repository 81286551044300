import React, { useEffect } from 'react';
import { App } from '@capacitor/app';

const CapacitorEventsListener = () => {
    
    useEffect(() => {
		App.addListener('backButton', data => {
			if(!data.canGoBack){
			  	App.exitApp();
			} else {
			  	window.history.back();
			}
		});

		return function cleanup() {
            App.removeAllListeners();
		}
	}, []);

    return(<></>)
};

export default CapacitorEventsListener;