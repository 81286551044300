import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Routes, Route, Navigate, useLocation, useSearchParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { getUser, setUser } from 'helpers/user';
import { setLanguage } from "helpers/i18n";
import { getAjaxUrl, setMaintenanceMode } from 'helpers/config';
import useStore from "store";

import PushHandler from "components/PushHandler";
import CapacitorEventsListener from "components/CapacitorEventsListener";
import SetDeviceBodyClass from "components/SetDeviceBodyClass";

import Login from "pages/Login";
import Logout from "pages/Logout";
import Register from "pages/Register";
import Splash from "pages/Splash";

import Dashboard from "pages/Dashboard";
import Consumptions from "pages/Consumptions";
import Refuelings from "pages/Refuelings";
import TicketPreview from "pages/Refuelings/TicketPreview";
import Invoices from "pages/Invoices";
import Account from "pages/Account";
import { default as AccountUserEdit } from "pages/Account/UserEdit";
import { default as InvoicingUserEdit } from "pages/Account/InvoicingEdit";
import Map from "pages/Map";
import StationsList from "pages/StationsList";
import Station from "pages/Station";
import Wash from "pages/Wash";
import Messages from "pages/Messages";
import MaintenanceMode from 'components/MaintenanceMode';

const MainContainer = styled.div`
	height: 100%;
`;

function App() {
	const location = useLocation();

    const forceReload = useStore(state => state.forceReload);
    const maintenanceMode = useStore(state => state.maintenanceMode);
	const lang = useStore(state => state.lang); // Needed to update lang on app on lang change from selector

	// Fix for dev lang
	const [searchParams] = useSearchParams();
	let langSP = searchParams.get('lang');
	if ( langSP ) setLanguage(langSP);
	
	// Axios global
    axios.defaults.baseURL = getAjaxUrl();
	axios.defaults.headers.common['Authorization'] = getUser()?.accessToken ? 'Bearer ' + getUser()?.accessToken : null;
	axios.defaults.headers.common['AppLanguage'] = lang;
    
    // Axios interceptors
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        // Check unauthorized error
        if ( error.response && error.response.status === 401 ) {
			if (getUser()?.id) {
				setUser(null, true);
				window.location.reload();
			}
			
        }

        if ( error.response && error.response.status === 503 ) {
			setMaintenanceMode(error.response.status === 503);
		}

        // Default
        return Promise.reject(error);
    });

	return (
		<React.Fragment>
			<SetDeviceBodyClass />
			<CapacitorEventsListener />
			{maintenanceMode && <MaintenanceMode />}

			<CapacitorEventsListener />

			{ getUser()?.id && <PushHandler /> }

			<MainContainer forceReload={forceReload} lang={lang}>
				{ (
					!getUser() && location.pathname !== '/login' && 
					location.pathname !== '/register' && 
					location.pathname !== '/' &&
					location.pathname !== '/map' &&
					location.pathname.indexOf('/station/') === -1 &&
					location.pathname.indexOf('/wash/') === -1 &&
					location.pathname !== '/stations-list'
				  ) &&
					<Navigate to="/login" />
				}

				<Routes>
					<Route path="login" element={<Login />} />
					<Route path="logout" element={<Logout />} />
					<Route path="register" element={<Register />} />
					<Route path="/map" element={<Map />} />
					<Route path="/stations-list" element={<StationsList />} />
					<Route path="/station/:id" element={<Station />} />
					<Route path="/wash/:id" element={<Wash />} />

					{getUser()?.id ?
						<React.Fragment>
							<Route path="/" element={<Dashboard />} />
							<Route path="/consumptions" element={<Consumptions />} />
							<Route path="/refuelings" element={<Refuelings />} />
							<Route path="/refueling/ticket-preview/:id" element={<TicketPreview />} />
							<Route path="/invoices" element={<Invoices />} />
							<Route path="/account" element={<Account />} />
							<Route path="/account/user-edit" element={<AccountUserEdit />} />
							<Route path="/account/invoicing-edit" element={<InvoicingUserEdit />} />
							<Route path="/messages" element={<Messages />} />
						</React.Fragment>
						:
						<React.Fragment>
							<Route path="/" element={<Splash />} />
						</React.Fragment>
					}
				</Routes>

				<ToastContainer
					position="top-center"
					autoClose={2000}
				/>
			</MainContainer>
		</React.Fragment>
	);
}

export default App;