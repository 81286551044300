import axios from 'axios';

export default class Auth {

	static axiosCancelToken = axios.CancelToken.source();
	
	static login = (vatnumber, password) => {
		this.newAxiosCancelToken();

		return axios.post('api/auth/login', {
            vatnumber: vatnumber,
            password: password
        }).then((response) => {
			return response.data;
        }).catch((error) => {           
			return false;
        });
	}
	
	static recoverPassword = (vatnumber) => {
		this.newAxiosCancelToken();

		return axios.post('api/auth/recoverPassword', {
            vatnumber: vatnumber,
        }).then((response) => {
			return true
        }).catch((error) => {           
			if ( error.response.status === 429 ) {
				return -1;
			}
			return false;
        });
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}