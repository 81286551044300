import React from 'react';
import styled from 'styled-components';
import { useMap } from 'react-leaflet';
import { assets } from 'helpers/generic';

const StyledZoomButtons = styled.div` 
	position: absolute;
	display: flex;
	bottom: 30px;
	left: 20px;
	z-index: 9999;

	button {
		border: 0;
		background: white;
		color: var(--plenoil-black);
		width: 30px;
		height: 30px;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		margin-right: 10px;
		font-size: 25px;
		line-height: 25px;
		box-shadow: var(--plenoil-shadow);
		cursor: pointer !important;

		img {
			opacity: 0.5;
			width: 50%;
		}
	}
`;

const Buttons = (props) => {
	const map = useMap();

	const zoomIn = () => {
		map.zoomIn();
	}

	const zoomOut = () => {
		map.zoomOut();
	}

	return (
	    <StyledZoomButtons id="zoom-buttons">
			<button onClick={zoomOut}><img src={assets('assets/img/mapa-menos.svg')} alt="-" /></button>
			<button onClick={zoomIn}><img src={assets('assets/img/mapa-mas.svg')} alt="+" /></button>
		</StyledZoomButtons>
  	);
}

export default Buttons;