import React, { useEffect, useState } from 'react';
import { useParams, NavLink, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { default as StationService } from 'services/Station';
import { assets } from 'helpers/generic';
import { __ } from 'helpers/i18n';
import OpenMapButton from 'components/OpenMapButton';

const ContainerStyled = styled.div` 	
	margin-top: 30px 0;

    #back-btn {
        text-decoration: none;
        display: block;
        margin: 0 auto;
        width: 100px;
        text-align: center;
        color: #333;
        margin-top: 30px;
        font-weight: 700;
    }
`;

const WashStyled = styled.div` 
	.station {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 40px 20px;

		.left {
			margin-right: 20px;

            & > div {
				text-decoration: none;	
			}
	
			button {
				padding: 0;
				text-decoration: none;
				color: var(--bs-gray-700);
				font-size: 10px;
				margin-top: 2px;
				width: 30px;

				img {
					width: 20px;
				}
			}
		}

		.right {
			.name {
				font-size: 24px;
				line-height: 24px;
				font-weight: 700;
			}

			.address {
				margin-top: 3px;
				font-size: 18px;
				line-height: 18px;
				font-weight: 500;
			}
		}
	}
	
    .price {
        background: var(--plenoil-blue);
        color: var(--plenoil-yellow);
        padding: 15px;
        font-size: 22px;
        text-align: center;
        font-weight: bold;
    }

    .services {
        max-width: 90%;
        margin: 0 auto;
        padding: 10px;
        padding-top: 30px;
        text-align: center;
        display: flex;
        flex-flow: row wrap; 
        justify-content: center;

        .service_square {
            max-width: 140px;
            width: 100%;
            display: inline-block;
            margin: 10px 10px;

            img {

            }   

            span {
                display: flex;
                width: 100%;
                justify-content: center;
                font-size: 11px;
                line-height: 12px;
                font-weight: bold;
                text-transform: uppercase;
                margin-top: 10px;
                white-space: pre-wrap;
                /* word-break: break-word; */
            }
        }
    }
`;

const Modal = styled.div` 
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
        position: relative;
        width: 80%;
        padding: 10px;
        border-radius: 20px;
        background: #FF8200;

        .close {
            width: 25px;
            position: absolute;
            right: 10px;
            top: 10px;
        }

        .icon {
            width: 130px;
            display: block;
            margin: 0 auto;
            margin-top: 20px;
        }

        .title {
            color: #002F73;
            font-weight: 800;
            font-size: 30px;
            text-align: center;
            text-transform: uppercase;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .text {
            color: white;
            font-size: 18px;
            text-transform: uppercase;
            max-width: 250px;
            width: 100%;
            margin: 0 auto;
            font-weight: 400;
            margin-bottom: 20px;
            text-align: center;
            line-height: 30px;
        }
    }
`;

const Wash = () => {
	let params = useParams();
	let id = params.id;

	let [station, setStation] = useState(null);
    let [showModal, setShowModal] = useState(false);

	useEffect(() => {
		let getStation = async () => {
			let station = await StationService.getById(id);
			if ( station ) setStation(station);
		}
		getStation();

		return function cleanup() {
			StationService.cancel();
		}
	}, [id]);

    const showInfo = (e) => {
		let target = e.currentTarget;

        let infoToShow = {
            icon: assets('/assets/img/' + target.dataset.icon),
            title: target.dataset.title,
            text: target.dataset.text
        };

        setShowModal(infoToShow);
    }

    if ( station && !station.wash ) return <Navigate to="/map" />;
 
	return (
		<LoggedLayout logo='plenoil-wash.png'>
			<ContainerStyled>
				{station?.id &&
					<WashStyled>
						<div className="station">
							<div className="left">
                                <OpenMapButton className="btn btn-sm btn-link" latitude={station?.latitude} longitude={station?.longitude}>
                                    <img src={assets('/assets/img/ICONOS-MAPA.svg')} alt="map" />	
									{__('wash.mapButton')}
								</OpenMapButton>
							</div>
							<div className="right">
								<div className="name">
									{station.name ?? '-'}
								</div>
								<div className="address">
									{station.street ?? '-'}
								</div>
							</div>
						</div>

                        <div className="price">
                            {station.wash_price}€ - {station.wash_minutes} {__('wash.minutes')}
                        </div>

                        <div className="services">
                            { station.wash_services && station.wash_services.lavallantas === "1" && 
                                <div className="service_square" data-text={__('wash.lavallantas.text1') + '<br />' + __('wash.lavallantas.text2')} data-title={__('wash.lavallantas.title')} data-icon="wash-lavallanta.svg?fixCache=20042023" onClick={(e) => showInfo(e)}>
                                    <img src={assets('/assets/img/wash-lavallanta.svg') + '?fixCache=20042023'} alt="lavallantas" />
                                    <span>{__('wash.lavallantas.title')}</span>
                                </div>
                            }
                            { station.wash_services && station.wash_services.prelavado === "1" && 
                                <div className="service_square" data-text={__('wash.prelavado.text1') + '<br />' + __('wash.prelavado.text2')} data-title={__('wash.prelavado.title')} data-icon="wash-prelavado.svg?fixCache=20042023" onClick={(e) => showInfo(e)}>
                                    <img src={assets('/assets/img/wash-prelavado.svg') + '?fixCache=20042023'} alt="prelavado" />
                                    <span>{__('wash.prelavado.title')}</span>
                                </div>
                            }
                            { station.wash_services && station.wash_services.lavado === "1" && 
                                <div className="service_square" data-text={__('wash.lavado.text1') + '<br />' + __('wash.lavado.text2')} data-title={__('wash.lavado.title')} data-icon="wash-lavado.svg?fixCache=20042023" onClick={(e) => showInfo(e)}>
                                    <img src={assets('/assets/img/wash-lavado.svg') + '?fixCache=20042023'} alt="lavado" />
                                    <span>{__('wash.lavado.title')}</span>
                                </div>
                            }
                            { station.wash_services && station.wash_services.aclarado === "1" && 
                                <div className="service_square" data-text={__('wash.aclarado.text1') + '<br />' + __('wash.aclarado.text2')} data-title={__('wash.aclarado.title')} data-icon="wash-aclarado.svg?fixCache=20042023" onClick={(e) => showInfo(e)}>
                                    <img src={assets('/assets/img/wash-aclarado.svg') + '?fixCache=20042023'} alt="aclarado" />
                                    <span>{__('wash.aclarado.title')}</span>
                                </div>
                            }
                            { station.wash_services && station.wash_services.superencerado === "1" && 
                                <div className="service_square" data-text={__('wash.superencerado.text1') + '<br />' + __('wash.superencerado.text2')} data-title={__('wash.superencerado.title')} data-icon="wash-superencerado.svg?fixCache=20042023" onClick={(e) => showInfo(e)}>
                                    <img src={assets('/assets/img/wash-superencerado.svg') + '?fixCache=20042023'} alt="superencerado" />
                                    <span>{__('wash.superencerado.title')}</span>
                                </div>
                            }
                            { station.wash_services && station.wash_services.superencerado2 === "1" && 
                                <div className="service_square" data-text={__('wash.superencerado2.text1') + '<br />' + __('wash.superencerado2.text2')} data-title={__('wash.superencerado2.title')} data-icon="wash-superencerado.svg?fixCache=20042023" onClick={(e) => showInfo(e)}>
                                    <img src={assets('/assets/img/wash-superencerado.svg') + '?fixCache=20042023'} alt="superencerado" />
                                    <span>{__('wash.superencerado2.title')}</span>
                                </div>
                            }
                            { station.wash_services && station.wash_services.abrillantado === "1" && 
                                <div className="service_square" data-text={__('wash.abrillantado.text1') + '<br />' + __('wash.abrillantado.text2')} data-title={__('wash.abrillantado.title')} data-icon="wash-abrillantado.svg?fixCache=20042023" onClick={(e) => showInfo(e)}>
                                    <img src={assets('/assets/img/wash-abrillantado.svg') + '?fixCache=20042023'} alt="abrillantado" />
                                    <span>{__('wash.abrillantado.title')}</span>
                                </div>
                            }
                            { station.wash_services && station.wash_services.abrillantado2 === "1" && 
                                <div className="service_square" data-text={__('wash.abrillantado2.text1') + '<br />' + __('wash.abrillantado2.text2')} data-title={__('wash.abrillantado2.title')} data-icon="wash-abrillantado.svg?fixCache=20042023" onClick={(e) => showInfo(e)}>
                                    <img src={assets('/assets/img/wash-abrillantado.svg') + '?fixCache=20042023'} alt="abrillantado" />
                                    <span>{__('wash.abrillantado2.title')}</span>
                                </div>
                            }
                        </div>
					</WashStyled>
				}
                { showModal &&
                    <Modal id="modal">
                        <div className="inner">
                            <div className="close" onClick={() => setShowModal(false)}><img src={assets('/assets/img/cerrar-plenoil.svg')} alt="close" /></div>
                            <div className="icon"><img src={showModal.icon} alt="icon" /></div>
                            <div className="title">{showModal.title}</div>
                            <div className="text" dangerouslySetInnerHTML={{__html: showModal.text}}></div>
                        </div>
                    </Modal>
                }
                <NavLink to={-1} id="back-btn">&lt; {__('wash.backButton')}</NavLink>
			</ContainerStyled>
		</LoggedLayout>
	);
}

export default Wash;