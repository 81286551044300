import React from 'react';
import styled from 'styled-components';
import { getUser } from 'helpers/user';
import { __ } from 'helpers/i18n';

const UserMetaStyled = styled.div` 
	text-align: center;
	width: 280px;
	margin: 0 auto;

	font-size: 12px;

	div {
		font-weight: bold;
		font-size: 14px;
	}

	border-bottom: 2px solid var(--bs-gray-200);

	padding-bottom: 8px;
`;

export default function UserMeta() {
	
	return (
        <UserMetaStyled>
            {__('dashboard.userMeta.welcome')}
			<div>{getUser()?.name}</div>
        </UserMetaStyled>
	);
}


