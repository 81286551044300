import React from "react";
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import NotLoggedLayout from 'layouts/NotLoggedLayout';
import { assets, jsonToArray } from 'helpers/generic';
import { getLanguages, setLanguage, __ } from 'helpers/i18n';

const LoginStyled = styled.div` 
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	#lang-selector {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 10px;

		select {
			padding: 2px 5px;

			option {
				padding: 0;
				text-align: left;
			}
		}
	}

	#animation-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 70%;

		#circle {
			width: 100px;
			display: block;
			animation: rotation 1.5s normal ease-in-out;

			@keyframes rotation {
				from {
					transform: rotate(0deg);
				}
				to {
					transform: rotate(360deg);
				}
			}
		}	

		#logo {
			margin-top: 15px;
			width: 180px;

			opacity: 0;
			animation-name: fadeIn;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
			animation-duration: 2s;
			animation-delay: 1s;
		}

		#text {
			font-size: 17px;
			line-height: 18px;
			text-align: center;
			font-weight: 600;
			margin-top: 20px;

			opacity: 0;
			animation-name: fadeIn;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
			animation-duration: 1s;
			animation-delay: 2.5s;
		}

		@keyframes fadeIn {
			from {
				transform: translate(0, 15px);
				opacity: 0;
			}
			to {
				transform: translate(0, 0);
				opacity: 1;
			}
		}

		#shadow {
			width: 180px;
			height: 30px;
			margin-top: 20px;

			img {
				width: 100%;
			}
		}
	}
	

	#login-form {
		display: flex;
		flex-direction: column;
		justify-content: end;
		width: 100%;
		height: 30%;
		margin: 0 auto;
		padding: 10px 0;

		a#btn-map {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: auto;
			margin-top: 20px;
			font-size: 12px;
			padding: 2px;
			padding-right: 12px;
			opacity: 0.9;
			margin-bottom: 20px;

			div.image-wrapper {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				background: #fcebd9;
				width: 25px;
				height: 25px;
				margin-right: 10px;
				padding: 5px;
				border-radius: 50%;

				img {
					width: 90%;
					height: 90%;
				}
			}
		}

		button {
			width: 100%;
			margin-top: 20px;
			font-size: 16px;
			padding: 5px 10px;
		}

		#new-account {
			color: var(--plenoil-blue2) !important;
			font-size: 18px;
			text-decoration: underline;
			padding-top: 0 !important;
		}
		
		.privacy-link {
			color: var(--bs-gray-800);
			text-decoration: unset !important;
			font-size: 12px;
		}
	}

	
`;

export default function Splash() {

	let languages = getLanguages();
	languages = jsonToArray(languages);

	const setLang = (lang) => {
		if ( !lang ) return;
		setLanguage(lang);
	}
	
	return (
		<NotLoggedLayout>
			<LoginStyled>
				<div id="lang-selector">
					<select onChange={(e) => setLang(e.target.value)} value="" className="btn btn-plenoil btn-plenoil-blue2">
						<option value="">{__('splash.langselector.label')}</option>
						{languages.map(el => 
							<option key={el.key} value={el.key}>{el.value}</option>
						)}
					</select>
				</div>
				<div id="animation-wrapper">
					<img id="circle" src={assets('/assets/img/Plenoil-simple.svg')} alt="circle" />
					<img id="logo" src={assets('/assets/img/Plenoil-logo-2022.svg')} alt="logo" />
					<div id="text">
						{__('splash.animation.text1')}<br />
						{__('splash.animation.text2')}
					</div>
					<div id="shadow">
						<img src={assets('/assets/img/sombra.png')} alt="shadow" />
					</div>
				</div>

				<div id="login-form">
					<div className="text-center">
						<NavLink to="/map" id="btn-map" className="btn btn-plenoil btn-plenoil-orange3">
							<div className="image-wrapper"><img src={assets('/assets/img/icono_mapa_naranja.svg')} alt="icon" /></div>
							{__('splash.mapbutton')}
						</NavLink>
					</div>
					
					<NavLink to="login" id="btn-login" className="btn btn-plenoil btn-plenoil-blue2 w-100">{__('splash.loginbutton')}</NavLink>

					<div className="text-center small mt-3">
						{__('splash.ask')}
					</div>
					<div className="text-center">
						<NavLink className="btn btn-plenoil btn-plenoil-white" to="/register" id="new-account">{__('splash.createaccountbutton')}</NavLink>
					</div>
					<div className="text-center mt-3">
						<a href="https://plenoil.es/aviso-legal/" className="privacy-link" target="_blank" rel="noreferrer">{__('splash.policylink')}</a>
					</div>
				</div>
			</LoginStyled>
		</NotLoggedLayout>
	);
}