import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink, useNavigate } from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import CristalLoader from 'components/CristalLoader';
import { default as UsersService } from 'services/User';
import { default as StationService } from 'services/Station';
import { __ } from 'helpers/i18n';

const ContainerStyled = styled.div` 
	margin-top: 30px 0;

	h2 {
		font-size: 15px;
	}

	input.input-plenoil,
	select.input-plenoil {
		padding: 10px 20px;
		font-size: 15px;
	}

	.btn-plenoil {
		padding: 10px 50px;
		font-size: 15px;
	}
`;

const ConcentradorProcessCristal = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.8);
	border-radius: 30px;
	padding: 20px;

	span {
		background: white;
		color: var(--plenoil-blue2);
		border-radius: 30px;
		text-align: center;
		display: block;
		padding: 10px 20px;
		box-shadow: var(--plenoil-shadow);
	}
	&:after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		font-size: 20px;
		text-align: center;
		background: white;
	}
`;

const DataWrapper = styled.div` 
	position: relative;
	padding: 10px 20px;

	.label-plenoil {
		padding-left: 0;
		color: var(--bs-gray-600);
	}

	.data-field {
		color: black;
		font-weight: 500;
		font-size: 13px;
		border-bottom: 1px solid var(--bs-gray-400);
		padding-bottom: 5px;
	}
`;

const Buttons = styled.div` 
	position: fixed;
	bottom: 100px;
	padding: 0 10px;
	left: 0;
	width: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	.btn-plenoil {
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
		font-size: 11px;
		line-height: 11px;
		padding: 8px;
		height: 30px;
		text-decoration: none;

		&:not(:last-of-type) {
			margin-right: 8px;
		}

		i {
			margin-left: 10px;
			font-size: 18px;
			line-height: 0px;
		}
	}

	select {
		margin-left: 8px;
		width: 130px;
		border: 0;

		padding-top: 7px !important;

		option {
			background: white;
			color: black;
		}
	}
`;

let checkConcentradorProcessInterval = null;

export default function Account() {
	let navigate = useNavigate();

	let [data, setData] = useState({});
	let [forceReload, setForceReload] = useState(null);
	let [loading, setLoading] = useState(false);
	let [stations, setStations] = useState([]);

	useEffect(() => {
		const getData = async () => {
			setLoading(true);

			let data = await UsersService.getData();
			setData(data);

			let stations = await StationService.listByProvinces();
			setStations(stations);

			setLoading(false);
		}
		getData();
	}, [forceReload]);

	useEffect(() => {
		if ( data?.concentrador_process ) {
			clearInterval(checkConcentradorProcessInterval);
			checkConcentradorProcessInterval = setInterval(() => {
				setForceReload(Math.random());
			}, 5000);
		}

		return function cleanup() {
			clearInterval(checkConcentradorProcessInterval);
		}
	}, [data]);

	const selectFavoriteStation = async (station_id) => {
		setLoading(true);
		await UsersService.setFavoriteStation(station_id);
		navigate('/');
		setLoading(false);
	}
	
	return (
		<LoggedLayout>
			{loading && <CristalLoader />}
			<ContainerStyled>
				<div className="page-title">{__('account.index.title')}</div>

				<DataWrapper>
					{ data?.concentrador_process && <ConcentradorProcessCristal><span>{__('account.index.concentrador.onprogressmessage')}</span></ConcentradorProcessCristal> }
					
					<div className="mb-1">
						<label className="label-plenoil">{__('account.index.inputs.name')}</label>
						<div className="data-field">{data?.name ?? ''}&nbsp;</div>
					</div>
				
					<div className="mb-1">
						<label className="label-plenoil">{__('account.index.inputs.email')}</label>
						<div className="data-field">{data?.email ?? ''}&nbsp;</div>
					</div>
				
					<div className="mb-1">
						<label className="label-plenoil">{__('account.index.inputs.phone')}</label>
						<div className="data-field">{data?.phone ?? ''}&nbsp;</div>
					</div>
				
					<div className="mb-1">
						<label className="label-plenoil">{__('account.index.inputs.address')}</label>
						<div className="data-field">{data?.address ?? ''}&nbsp;</div>
					</div>
				
					<div className="mb-1">
						<label className="label-plenoil">{__('account.index.inputs.province')}</label>
						<div className="data-field">{data?.province ?? ''}&nbsp;</div>
					</div>
				
					<div className="mb-1">
						<label className="label-plenoil">{__('account.index.inputs.city')}</label>
						<div className="data-field">{data?.city ?? ''}&nbsp;</div>
					</div>
				
					<div className="mb-1">
						<label className="label-plenoil">{__('account.index.inputs.postalcode')}</label>
						<div className="data-field">{data?.postalcode ?? ''}&nbsp;</div>
					</div>
				</DataWrapper>	

				<Buttons>
					<NavLink to="/account/user-edit" className="btn-plenoil btn-plenoil-blue2">
						{__('account.index.editButton')}
					</NavLink>
					<NavLink to="/account/invoicing-edit" className="btn-plenoil btn-plenoil-blue2">
						{__('account.index.editInvoicingButton')}
					</NavLink>
					<select className="btn-plenoil btn-plenoil-blue2" onChange={(e) => selectFavoriteStation(e.target.value)}>
						<option value="">{__('account.index.favoriteStation.placeholder')}</option>
						{stations?.map((el, idx) => {
							if ( !el.stations.length ) return null;

							let stations = [];
							stations.push(<option value='-' key={'province-' + idx} disabled style={{fontSize: '15px'}}>- {el.province} -</option>)
							el.stations.forEach((s, sIdx) => {
								stations.push(<option value={s.id} key={'province-' + idx + '-' + sIdx}>{s.name}</option>);
							});
							return stations;
						})}
					</select>
				</Buttons>
			</ContainerStyled>
		</LoggedLayout>
	);
}


