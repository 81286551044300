import React, { useEffect, useState } from 'react';
import moment from 'moment/min/moment-with-locales';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { formatNumber, jsonToArray, ucfirst } from 'helpers/generic';
import BarsReport from './BarsReport';
import PieReport from './PieReport';
import { default as ReportService } from 'services/Report';
import { default as RepostajeService } from 'services/Repostaje';
import { __, getLanguage } from 'helpers/i18n';

const ContainerStyled = styled.div` 	
	margin-top: 30px 0;

	#toggle-btn {
		text-align: center;
		font-weight: 800;
		margin-bottom: 15px;
	}
`;


const MensualReport = styled.div` 
	h1 {
		color: var(--plenoil-orange3);
		font-size: 40px;
		text-align: center;
		font-weight: 800;
	}

	#this_month {
		margin-top: -10px;
		text-align: center;
		color: black;
		font-weight: 500;
	}

	#liters {
		text-align: center;
		color: black;
		font-weight: 500;
	}

	#last_repostaje {
		text-align: center;
		color: black;
		font-weight: 500;
		margin-top: 15px;
	}

	margin-bottom: 20px;
`;


const BarsWrapper = styled.div` 
	margin: 15px;
`;

const PieReportWrapper = styled.div` 
	height: 100%;
	margin: 15px;

	display: flex;
	flex-direction: column;
	align-items: space-between;
	justify-content: space-between;
	height: 100%;
`;

export default function Consumptions() {
	let [plates, setPlates] = useState([]);
	let [year, setYear] = useState(moment().format('YYYY'));
	let [month, setMonth] = useState(moment().format('MM'));
	let [plate, setPlate] = useState(null);
	let [statistics, setStatistics] = useState([]);
	let [reportMonthSummary, setReportMonthSummary] = useState(null);
	let [repostajeMonthSummary, setRepostajeMonthSummary] = useState(null);
	let [salesTotalByMatricula, setSalesTotalByMatricula] = useState(null);
	let [active, setActive] = useState('bars');

	useEffect(() => {
		let getPlates = async () => {
			let plates = await ReportService.listMatriculas();
			setPlates(plates);
		}
		getPlates();
	}, []);

	useEffect(() => {
		let getStatistics = async () => {
			let statistics = await ReportService.getSalesTotalByMonths(year, plate);
			setStatistics(statistics);
		}
		getStatistics();
	}, [year, plate]);

	useEffect(() => {
		let getMonthSummary = async () => {
			let reportMonthSummary = await ReportService.monthSummary(moment().format('YYYY-MM-DD'));
			setReportMonthSummary(reportMonthSummary);

			let repostajeMonthSummary = await RepostajeService.monthSummary(moment().format('YYYY-MM-DD'));
			setRepostajeMonthSummary(repostajeMonthSummary);

			let salesTotalByMatricula = await ReportService.getSalesTotalByMatricula(year, month);
			setSalesTotalByMatricula(salesTotalByMatricula);
		}
		getMonthSummary();
	}, [year, month]);

	return (
		<LoggedLayout>
			<ContainerStyled>
				<div className="page-title">{__('consumptions.title')}</div>

				<MensualReport>
					<h1>{formatNumber(repostajeMonthSummary?.totalEuros ?? 0, 2, true, true)} €</h1>
					<div id="this_month">{__('consumptions.mensualReport.thismonth')}</div>
					<div id="liters">{__('consumptions.mensualReport.liters')}: { formatNumber(reportMonthSummary?.litersOilDiesel ?? 0 + reportMonthSummary?.litersOil95 ?? 0, 0, true, true) }</div>
					<div id="last_repostaje">{__('consumptions.mensualReport.lastRefueling')}: {reportMonthSummary?.last_repostaje ? reportMonthSummary.last_repostaje : '-'}</div>
				</MensualReport>

				{ active === 'bars' &&
					<BarsWrapper className="box-plenoil">
						<div className="d-flex justify-content-between">
							<select className="box-select" value={year ?? ''} onChange={(e) => setYear(e.target.value)}>
								{(() => {
									let years = [];
									for(let i = 2018; i<=moment().format('YYYY'); i++) {
										years.push(<option key={i} value={i}>{i}</option>);
									}
									return years.reverse();
								})()}
							</select>

							<select className="box-select" value={plate ?? ''} onChange={(e) => setPlate(e.target.value)}>
								<option value="">{__('consumptions.barsReportFilters.matriculas.all')}</option>
								{plates && jsonToArray(plates).map((el, idx) => {
									return (
										<option key={idx} value={el.value}>{el.value}</option>
									)
								})}
							</select>
						</div>
						<BarsReport statistics={statistics} />
					</BarsWrapper>	
				}
				
				{ active === 'pie' &&
					<PieReportWrapper className="box-plenoil box-plenoil-padded">
						<div className="d-flex justify-content-between">
							<select className="box-select" value={month ?? ''} onChange={(e) => setMonth(e.target.value)}>
								{(() => {
									let months = [];
									for(let i = 1; i<=12; i++) {
										let tmpMonth = moment('2022-' + (i < 10 ? '0'+i : i ) + '-01').locale(getLanguage()).format('MMMM');
										months.push(<option key={i} value={(i < 10 ? '0'+i : i )}>{ucfirst(tmpMonth)}</option>);
									}
									return months;
								})()}
							</select>
							<select className="box-select" value={year ?? ''} onChange={(e) => setYear(e.target.value)}>
								{(() => {
									let years = [];
									for(let i = 2018; i<=moment().format('YYYY'); i++) {
										years.push(<option key={i} value={i}>{i}</option>);
									}
									return years.reverse();
								})()}
							</select>	
						</div>
						<PieReport statistics={salesTotalByMatricula} />
					</PieReportWrapper>	
				}

				<div id="toggle-btn" onClick={() => setActive(active === 'bars' ? 'pie' : 'bars')}>
					{ active === 'bars' ? __('consumptions.toggleButton.matriculas') : __('consumptions.toggleButton.resumen') }
				</div>
			</ContainerStyled>
		</LoggedLayout>
	);
}


