import axios from 'axios';

export default class Station {

	static axiosCancelToken = axios.CancelToken.source();
	
	static getById = (id) => {
		this.newAxiosCancelToken();

		return axios.get('api/stations/get', {
			params: {
				id: id
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}


	static getClosestByLL = (latitude, longitude) => {
		this.newAxiosCancelToken();

		return axios.get('api/stations/getClosestByLL', {
			params: {
				latitude: latitude,
				longitude: longitude
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static listByProvinces = (latitude, longitude, provinceId, withWash, withElectriccharger) => {
		this.newAxiosCancelToken();

		return axios.get('api/stations/listByProvinces', {
			params: {
				latitude: latitude,
				longitude: longitude,
				province_id: provinceId,
				with_wash: withWash,
				with_electriccharger: withElectriccharger,
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static listProvinces = () => {
		this.newAxiosCancelToken();

		return axios.get('api/register/provinces', {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}
	
	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}