import React, { useEffect, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { default as StationService } from 'services/Station';
import { default as UserService } from 'services/User';
import { assets, formatNumber } from 'helpers/generic';
import { getUser } from 'helpers/user';
import { __, getLanguage } from 'helpers/i18n';
import useStore from "store";
import OpenMapButton from 'components/OpenMapButton';

const ContainerStyled = styled.div` 	
	margin-top: 30px 0;
	
	.page-title {
		width: 350px;
		border-bottom: 0;
		margin-bottom: 0;
	}
`;

const StationStyled = styled.div` 
	div.image-wrapper {
		height: 200px;
		overflow: hidden;
		position: relative;

		img {
			width: 100%;
		}

		button {
			position: absolute;
			top: 15px;
			right: 15px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			border: 0;
			background: white;
		}
	}	

	.prices {
		position: relative;
		z-index: 2;
		width: 100%;
		height: 60px;
		margin-top: -10px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > div {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			color: white;
			width: 50%;
			height: 100%;
			border-radius: 5px;
			font-weight: 500;

			div.name {
				font-size: 13px;
				line-height: 10px;
				margin-top: 8px;
			}

			div.price {
				font-size: 18px;
			}

			margin-left: 2.5px;
			margin-right: 2.5px;
		}

		& > .goa {
			background: var(--plenoil-black);
		}

		& > .sp95 {
			background: var(--plenoil-green);
		}

		& > .adblue {
			background: var(--plenoil-blue2);
		}

		& > .electriccharger {
			background: var(--plenoil-orange);

			.name {
				margin-top: 0;
			}
		}
	}

	.details {
		display: flex;
		align-items: center;
		margin: 0 auto;
		margin-top: 20px;
		padding-bottom: 10px;
		width: 90%;
    	border-bottom: 2px solid var(--plenoil-orange);

		.left {
			margin-left: 10px;
			margin-right: 10px;

			& > div {
				text-decoration: none;	
			}
	
			button {
				padding: 0;
				text-decoration: none;
				color: var(--bs-gray-700);
				font-size: 10px;
				margin-top: 2px;
				width: 30px;

				img {
					width: 20px;
				}
			}
		}

		.right {
			.name {
				font-size: 24px;
				line-height: 24px;
				font-weight: 700;
			}

			.address {
				margin-top: 3px;
				font-size: 18px;
				line-height: 18px;
				font-weight: 500;
			}
		}
	}
	
	.list {
		margin: 15px auto;
		padding: 0 10px;
		padding-bottom: 10px;
		width: 90%;
    	border-bottom: 2px solid var(--plenoil-orange);

		& > div {
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			img {
				width: 18px;
				height: 18px;
				margin-right: 10px;
			}

			.text {
				font-size: 14px;
				font-weight: 500;
			}
		}
	}

	.promos_list {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.promo-row {
			display: block;
			width: 100%;
			padding: 10px;
			font-weight: bold;

			img {
				max-width: 90%;
				display: block;
				margin: 0 auto;
			}
		}
	}

	.bottom-buttons {
		padding: 0px 30px;

		#btn-wash {
			float: right;
			margin-right: 10px;

			img {
				width: 100px;
			}
		}

		#back-btn {
			float: left;
			text-decoration: none;
			display: block;
			margin: 0 auto;
			text-align: center;
			color: #333;
			font-weight: 700;
			margin-left: 10px;
		}

		margin-bottom: 30px;
	}
`;

const Station = () => {	
	let params = useParams();
	let id = params.id;

	const lang = useStore(state => state.lang); // Needed to update lang on app on lang change from selector

	let [forceReload, setForceReload] = useState(null);
	let [station, setStation] = useState(null);
	let [favoriteStation, setFavoriteStation] = useState(null);

	useEffect(() => {
		let getStation = async () => {
			let station = await StationService.getById(id);
			if ( station) setStation(station);
		}
		getStation();

		let getFavoriteStation = async () => {
			let data = await UserService.getData();
			setFavoriteStation(data?.favorite_station ?? null);
		}
		if ( getUser()?.id ) getFavoriteStation();

		return function cleanup() {
			StationService.cancel();
		}
	}, [id, forceReload, lang]);

	const updateFavoriteStation = async () => {
		await UserService.setFavoriteStation(favoriteStation?.id === station?.id ? null : station?.id);
		setForceReload(Math.random());
	}
 
	// Get calendar values
	let calendar_0 = null;
	try {
		calendar_0 = station?.calendar[getLanguage()][0] ?? null;
	} catch (e) {
		calendar_0 = station?.calendar && station?.calendar[0] ? station?.calendar[0] : null;
	}

	let calendar_1 = null;
	try {
		calendar_1 = station?.calendar[getLanguage()][1] ?? null;
	} catch (e) {
		calendar_1 = station?.calendar && station?.calendar[1] ? station?.calendar[1] : null;
	}

	return (
		<LoggedLayout>
			<ContainerStyled>
				<div className="page-title">{__('station.title')}</div>
				{station?.id &&
					<StationStyled>
						<div className="image-wrapper">
							{ getUser()?.id &&
								<button onClick={() => updateFavoriteStation()}>
									{	favoriteStation?.id && favoriteStation.id === station.id ?
										<img src={assets('/assets/img/corazon.svg')} alt="favorite" />	
										:
										<img src={assets('/assets/img/corazon-gris.svg')} alt="favorite" />	
									}
								</button>
							}
							<img src={station.photo_url ? station.photo_url : assets('/assets/img/mapa-fondo-area-clientes.jpg')} alt="station" />	
						</div>
						<div className="prices">
							<div className="goa">
								<div className="name">{__('station.diesel')}</div>
								<div className="price">{station.oilDiesel ? formatNumber(station.oilDiesel, 3, true, true) : '-,---'} €</div>
							</div>
							<div className="sp95">
								<div className="name">{__('station.sp95')}</div>
								<div className="price">{station.oil95 ? formatNumber(station.oil95, 3, true, true) : '-,---'} €</div>
							</div>
							{ station.adblue > 0 &&
								<div className="adblue">
									<div className="name">{__('station.adblue')}</div>
									<div className="price">{station.adblue ? formatNumber(station.adblue, 3, true, true) : '-,---'} €</div>
								</div>
							}
							{ station.electriccharger === 1 &&
								<div className="electriccharger">
									<div className="name">{__('station.electriccharger')}</div>
								</div>
							}
						</div>
						<div className="details">
							<div className="left">
								<OpenMapButton className="btn btn-sm btn-link" latitude={station?.latitude} longitude={station?.longitude}>
									<img src={assets('/assets/img/ICONOS-MAPA.svg')} alt="map" />	
									{__('station.mapButton')}
								</OpenMapButton>
							</div>
							<div className="right">
								<div className="name">
									{station.name ?? '-'}
								</div>
								<div className="address">
									{station.street ?? '-'}
								</div>
							</div>
						</div>
						<div className="list">
							{(station.opening && station.opening[lang]) &&
								<div>
									<img src={assets('/assets/img/icono-reloj-ficha.svg')} alt="icon" />
									<div className="text">
										{station.opening[lang]}
									</div>
								</div>
							}
							
							<div>
								<img src={assets('/assets/img/icono-atencion-ficha.svg')} alt="icon" />
								<div className="text">
									{__('station.personalized')}
								</div>
							</div>

							{ calendar_1 &&
								<div>
									<img src={assets('/assets/img/icono-calendario-ficha.svg')} alt="icon" />
									<div className="text">
										{ calendar_0 }
									</div>
								</div>
							}
							{ calendar_0 &&
								<div>
									<img src={assets('/assets/img/icono-calendario-ficha.svg')} alt="icon" />
									<div className="text">
										{ calendar_1 }
									</div>
								</div>
							}
							<div>
							<img src={assets('/assets/img/icono-mapa-ficha.svg')} alt="icon" />
								<div className="text">
									{ station.street }
								</div>
							</div>
						</div>
						{station.promos_list?.length > 0 &&
							<div className='promos_list'>
								{station.promos_list.map((promo, promoIdx) => {
									return (
										<div key={promoIdx} className="promo-row">
											{ promo.imageUrl ? 
												<img src={promo.imageUrl} alt={promoIdx} />
												:
												<div dangerouslySetInnerHTML={{ __html: promo.text }}></div>
											}
										</div>
									);
								})}
							</div>
						}
						<div className="bottom-buttons">
							<NavLink to={-1} id="back-btn">&lt; {__('wash.backButton')}</NavLink>
							{ station.wash === 1 &&
								<NavLink to={'/wash/' + station.id} id="btn-wash">
									<img src={assets('/assets/img/plenoil-wash.png')} alt="wash" />
								</NavLink>
							}
							<div className="clearfix"></div>
						</div>
					</StationStyled>
				}
			</ContainerStyled>
		</LoggedLayout>
	);
}

export default Station;