import React from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { assets } from 'helpers/generic';
import useStore from "./store";

// Prepare custom icon
const icon = new L.Icon({
    iconUrl: assets('/assets/img/ubicacion.svg'),
    iconRetinaUrl: assets('/assets/img/ubicacion.svg'),
    popupAnchor:  [-0, -0],
    iconSize: [20,20]
});

const CurrenPositionMarker = (props) => {
	const position = useStore(state => state.position);

	return position.latitude === null || position.longitude === null ? null : (
	    <Marker 
	    	position={[position.latitude, position.longitude]} 
	    	icon={icon} 
	    	key={'position'} 
			zIndexOffset={999999999}
	    />
  	);
}

export default CurrenPositionMarker;