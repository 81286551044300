import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useNavigate, NavLink } from 'react-router-dom';
import { Geolocation } from '@capacitor/geolocation';
import { assets } from "helpers/generic";
import LoggedLayout from 'layouts/LoggedLayout';
import { default as StationsService } from 'services/Station';
import { __ } from 'helpers/i18n';

const GlobalStyle = createGlobalStyle` 
	html, body {
		&.ios {
			#stations-list-container {
                height: calc(100% - 80px) !important;
            }
		}
	}

    .page-title {
        width: 100% !important; 
        border-bottom: 0 !important;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
`;

const ContainerStyled = styled.div` 
	position: relative;
    height: calc(100% - 120px);
    overflow: auto;
    
    margin: 10px;

    border-top: 2px solid var(--plenoil-orange);
    border-bottom: 2px solid var(--plenoil-orange);
`;

const List = styled.div` 
	padding: 15px;
    padding-top: 0;

    .province {
        margin-bottom: 35px;

        & > .name {
            font-size: 19px;
            border-bottom: 1px solid var(--plenoil-black-shadow);
            padding: 10px 0;
        }

        .station {
            font-size: 15px;
            line-height: 15px;
            display: flex;
            align-items: center;
            padding: 10px 0;
            border-bottom: 1px solid var(--plenoil-black-shadow);

            .name {
                width: 120px;
                min-width: 120px;
                padding-right: 10px;
            }

            .address {
                width: 100%;
                text-transform: uppercase;
                opacity: 0.8;
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 14px;

                img {
                    width: 12px;
                    margin-right: 10px;
                }
            }

            .distance {
                width: 60px;
                min-width: 60px;
                text-align: right;
                opacity: 0.8;
            }
        }
    }
`;

const Buttons = styled.div` 
    height: 50px;
    min-height: 50px;
    text-align: right;
    background: white;
    display: flex;
    align-items: center;
    justify-content: right;
    z-index: 2;

    a {
        display: inline-flex;
        align-items: center;
        margin-right: 40px;
        color: unset;
        text-decoration: none;
        font-size: 13px;
        font-weight: 500;

        img {
            width: 25px;
            margin-right: 10px;
        }
    }

    .form-switch {
        display: flex;
        align-items: center;

        .form-check-input:checked {
            background-color: var(--plenoil-orange);
            border-color: var(--plenoil-orange);
        }

        label {
            margin-left: 10px;

            img {
                width: 80px;
            }
        }
    }
`;

export default function StationsList() {
    const navigate = useNavigate();

	let [stations, setStations] = useState([]);
    let [withWash, setWithWash] = useState(false);
    let [position, setPosition] = useState({
        latitude: null,
        longitude: null
    });

	useEffect(() => {
		const getData = async () => {
			let stations = await StationsService.listByProvinces(position.latitude, position.longitude, null, withWash);
			setStations(stations);
		}
		getData();
	}, [withWash, position]);

    useEffect(() => {
        const getPositionInterval = setInterval(async () => {
            let coordinates = null;
			try {
				coordinates = await Geolocation.getCurrentPosition();
			} catch (e) {}

            setPosition({
                latitude: coordinates?.coords?.latitude,
                longitude: coordinates?.coords?.longitude
            });
		}, 5000);

        return function cleanup() {
            clearInterval(getPositionInterval);
        }
    }, []);

	return (
		<React.Fragment>
			<GlobalStyle />
			<LoggedLayout>
                <div className="page-title">{__('stationslist.title')}</div>

				<ContainerStyled id="stations-list-container">
					<List id="list">
                        {stations.map((province, provinceIdx) => {
                            if ( !province.stations.length ) return null;

                            return (
                                <div className="province" key={'province'+provinceIdx}>
                                    <div className="name">{province.province}</div>
                                    {province.stations.map((station, stationIdx) => {
                                        return (
                                            <div key={'station'+stationIdx} className="station" onClick={() => navigate('/station/' + station.id)}>
                                                <div className="name">{station.name}</div>
                                                <div className="address">
                                                    <img src={assets('/assets/img/ICONOS-MAPA.svg')} alt="icon" />
                                                    {station.street}
                                                </div>
                                                <div className="distance">{station.distance ? station.distance + ' Km' : ''} </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
					</List>
				</ContainerStyled>
                <Buttons>
                    <NavLink to="/map"><img src={assets('/assets/img/mapa-listado.svg')} alt="icon" /> {__('stationslist.ubication')}</NavLink>

                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" checked={withWash} onChange={() => setWithWash(!withWash)} id="wash_check" />
                        <label className="form-check-label" htmlFor="wash_check">
                            <img src={assets('/assets/img/plenoil-wash.svg')} alt="icon" />
                        </label>
                    </div>
                </Buttons>
			</LoggedLayout>
		</React.Fragment>
	);
}


